import { ImportType } from "types/IImportJob";

export const getImportType = (fileExtension: string | undefined) => {
  switch (fileExtension) {
    case "csv":
      return ImportType.Csv;
    case "json":
      return ImportType.Json;
    case "geojson":
      return ImportType.Json;
    case "sos":
      return ImportType.SosiPrikk;
    case "gml":
      return ImportType.SosiGml;
    default:
      return ImportType.Json;
  }
};

export const getFileReference = (sasUri: string) => {
  let urlWithoutQueryString = sasUri.split("?")[0];

  return urlWithoutQueryString.substring(
    urlWithoutQueryString.lastIndexOf("/") + 1
  );
};
