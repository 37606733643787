import { IUser } from './IUser';
import {
  updateUserBegin,
  updateUserFailure,
  updateUserSuccess,
  fetchLoggedInUserBegin,
  fetchLoggedInUserFailure,
  fetchLoggedInUserSuccess,
  fetchUserRolesBegin,
  fetchUserRolesFailure,
  fetchUserRolesSuccess
} from './user.action';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieves user object for logged in user.
 * @param access_token bearer access token
 */
export function getLoggedInUser(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchLoggedInUserBegin())
    return fetch(baseUrl.concat('/api/User/GetLoggedInUser'), {
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    }).then(async response => {
      if (!response.ok) {
        throw new Error(await response.text())
      }
      else return await response.json()
    })
      .then((user: IUser) => {
        dispatch(fetchLoggedInUserSuccess(user));
      })
      .catch((error: Error) => dispatch(fetchLoggedInUserFailure(NotificationMessages.GETUSER_FAILED.FETCH, error)));
  }
}

/**
 * Updates all fields on the given user objects
 * @param access_token bearer access token
 * @param users array of users with updated user objects.
 */
export function updateUser(access_token: string, user: IUser) {
  return (dispatch: any) => {
    dispatch(updateUserBegin());
    return fetch(baseUrl.concat('/api/user/'), {
      method: 'post',
      body: JSON.stringify([user]),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    }).then(async response => {
      if (!response.ok) {
        throw new Error(await response.text())
      }
    })
      .then(() => {
        dispatch(updateUserSuccess(NotificationMessages.UPDATEUSER_SUCCESS.FETCH));
      })
      .catch((error: Error) => dispatch(updateUserFailure(NotificationMessages.UPDATEUSER_FAILED.FETCH, error)));
  }
}
/**
 * Fetches roles for a logged in user
 * @param access_token bearer access token
 */
 export function getUserRoles(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchUserRolesBegin());
    return fetch(baseUrl.concat('/api/user/GetUserRoles'), {
      headers: new Headers({
        'Authorization': "Bearer " + access_token,
        'Cache-Control': 'no-cache',
        'Accept': 'application/json'
      })
    })
      .then(async response => {
        if (!response.ok) {
          var responseText = await response.text();
          if (responseText.includes("Du må samtykke til bruk av tjenesten.")) window.location.href = process.env.REACT_APP_SUCCESSFULL_LOGIN_REDIRECT_URL || ''
          throw new Error(responseText)
        }
        else return await response.json()
      })
      .then(json => {
        dispatch(fetchUserRolesSuccess(json));
      })
      .catch((error: Error) => dispatch(fetchUserRolesFailure(NotificationMessages.ROLES_FAILED.FETCH, error)));
  }
}