import React from "react";

const InProgressBadge = () => {
  return (
    <svg
      width="32"
      height="16"
      viewBox="0 0 32 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="16" rx="2" fill="#F15B0A" />
      <path
        d="M6.60369 3.27273V12H4.75852V3.27273H6.60369ZM10.8991 12V3.27273H12.7443V7.12074H12.8594L16 3.27273H18.2116L14.973 7.1804L18.25 12H16.0426L13.652 8.41193L12.7443 9.51989V12H10.8991ZM19.8949 12.4176L19.0639 11.8551L25.3153 2.87216L26.1463 3.43466L19.8949 12.4176ZM26.7003 7.63636C26.7003 8.58807 26.5199 9.39773 26.1591 10.0653C25.8011 10.733 25.3125 11.2429 24.6932 11.5952C24.0767 11.9446 23.3835 12.1193 22.6136 12.1193C21.8381 12.1193 21.142 11.9432 20.5256 11.5909C19.9091 11.2386 19.4219 10.7287 19.0639 10.0611C18.706 9.39347 18.527 8.58523 18.527 7.63636C18.527 6.68466 18.706 5.875 19.0639 5.20739C19.4219 4.53977 19.9091 4.03125 20.5256 3.68182C21.142 3.32955 21.8381 3.15341 22.6136 3.15341C23.3835 3.15341 24.0767 3.32955 24.6932 3.68182C25.3125 4.03125 25.8011 4.53977 26.1591 5.20739C26.5199 5.875 26.7003 6.68466 26.7003 7.63636ZM24.8295 7.63636C24.8295 7.01989 24.7372 6.5 24.5526 6.0767C24.3707 5.65341 24.1136 5.33239 23.7812 5.11364C23.4489 4.89489 23.0597 4.78551 22.6136 4.78551C22.1676 4.78551 21.7784 4.89489 21.446 5.11364C21.1136 5.33239 20.8551 5.65341 20.6705 6.0767C20.4886 6.5 20.3977 7.01989 20.3977 7.63636C20.3977 8.25284 20.4886 8.77273 20.6705 9.19602C20.8551 9.61932 21.1136 9.94034 21.446 10.1591C21.7784 10.3778 22.1676 10.4872 22.6136 10.4872C23.0597 10.4872 23.4489 10.3778 23.7812 10.1591C24.1136 9.94034 24.3707 9.61932 24.5526 9.19602C24.7372 8.77273 24.8295 8.25284 24.8295 7.63636Z"
        fill="white"
      />
    </svg>
  );
};

export default InProgressBadge;
