import React from "react";

const InProgressBadge = () => {
  return (
    <svg
      width="48"
      height="16"
      viewBox="0 0 48 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="16" rx="2" fill="#F1180A" />
      <path
        d="M4.75852 12V3.27273H10.5369V4.79403H6.60369V6.87358H10.1534V8.39489H6.60369V12H4.75852ZM11.7781 12V3.27273H17.6587V4.79403H13.6232V6.87358H17.3562V8.39489H13.6232V10.4787H17.6758V12H11.7781ZM20.9709 3.27273V12H19.1257V3.27273H20.9709ZM22.489 12V3.27273H24.3342V10.4787H28.0756V12H22.489ZM29.2976 12V3.27273H35.1783V4.79403H31.1428V6.87358H34.8757V8.39489H31.1428V10.4787H35.1953V12H29.2976ZM36.3129 4.79403V3.27273H43.4805V4.79403H40.8086V12H38.9847V4.79403H36.3129Z"
        fill="white"
      />
    </svg>
  );
};

export default InProgressBadge;
