import L from 'leaflet';
// eslint-disable-next-line
import { GeometryUtil } from 'leaflet-geometryutil';

/**
 * 
 * Calculates if the area of the provided layer is within legal bounds.
 * @param minLegal minimum legal search area in square meter
 * @param maxLegal maximum legal search area in square meter
 * @param layer the layer to validate
 * @returns {isSearchWithinBounds: bool, searchAreaSquareMeter: number}
 */
export function isSearchAreaLegal(minLegal, maxLegal, layer) {
    const geometryType = layer.toGeoJSON().geometry.type;
    let meters;
    if (geometryType === 'Polygon') {
        var polygonCoordinates = layer.getLatLngs()[0];
        meters = L.GeometryUtil.geodesicArea(polygonCoordinates);
    }
    else if (geometryType === 'LineString') {
        var polyline = L.polyline(layer.toGeoJSON().geometry.coordinates)
        meters = L.GeometryUtil.length(polyline);
    }
    if (meters < minLegal || meters > maxLegal) {
        return {isSearchWithinBounds: false, searchAreaSquareMeter: meters};
    }
    return {isSearchWithinBounds: true, searchAreaSquareMeter: meters};;
}