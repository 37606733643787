import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../redux/rootReducer";
import MapSearch from "../../common/map/containers/mapSearch";
import { useGeolocated } from "react-geolocated";
import { IProductOwner } from "../../common/map/models/IProductOwner";
import { removeProductOwnersData } from "../../common/map/mapSearch.actions";
import { handleHasRole } from "../../common/utilities/roleHandler";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { QueryTypes } from "../../common/map/models/QueryTypes";
import {
  getInfrastructureByGeometry,
  getConstructionsByGeometry,
} from "../../export/export.service";
import { InfrastructureType, getEnumKeyByValue } from "../../import/IProduct";

const Search = () => {
  const dispatch = useDispatch();
  const { coords, isGeolocationEnabled, isGeolocationAvailable } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const [position, setPosition] = useState<GeolocationCoordinates | undefined>(
    undefined
  );
  const [isDefaultLocation, setIsDefaultLocation] = useState(false);

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled) {
      setIsDefaultLocation(false);
      setPosition(coords);
    } else {
      const defaultPosition: GeolocationCoordinates = {
        latitude: 59.911491,
        longitude: 10.757933,
        accuracy: 0,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        speed: null,
      };
      setIsDefaultLocation(true);
      setPosition(defaultPosition);
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords, isDefaultLocation]);

  const mapObjects = useSelector((state: State) => state.mapData.owners);
  const showMapDataOwners = useSelector((state: State) =>
    state.mapData.owners ? true : false
  );
  const access_token = useSelector(
    (state: State) => state.auth.user && state.auth.user.access_token
  );
  const fetchLimitsError = useSelector(
    (state: State) => state.limits.fetchLimitsError
  );
  const userSearchArea = useSelector(
    (state: State) => state.mapData.userSearch?.area
  );
  const userSearchType = useSelector(
    (state: State) => state.mapData.userSearch?.type
  );
  const fetchOrgDataByGeoLoading = useSelector(
    (state: State) => state.exportProducts.fetchOrgDataByGeoLoading
  );
  const [triggerClearSearch, setTriggerClearSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleHasRole();
    setLoading(fetchOrgDataByGeoLoading);
  }, [fetchOrgDataByGeoLoading]);

  const handleClose = () => {
    dispatch(removeProductOwnersData());
    setTriggerClearSearch(true);
    setTimeout(() => setTriggerClearSearch(false), 0);
  };

  const handleDownloadSearchResult = () => {
    if (userSearchType === QueryTypes.Construction) {
      dispatch<any>(
        getConstructionsByGeometry(access_token ?? "", userSearchArea)
      );
    } else {
      dispatch<any>(
        getInfrastructureByGeometry(
          access_token ?? "",
          userSearchArea,
          userSearchType === QueryTypes.FIP
            ? [
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKapasitet
                ) || "",
              ]
            : [
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomFøringsvei
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKabinett
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKulvert
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomKum
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomStolpe
                ) || "",
                getEnumKeyByValue(
                  InfrastructureType,
                  InfrastructureType.NkomTårn
                ) || "",
              ]
        )
      );
    }
  };

  if (fetchLimitsError || !position) {
    return <></>;
  } else {
    return (
      <div className="uk-position-relative">
        <MapSearch
          lat={position.latitude}
          lng={position.longitude}
          zoom={!isDefaultLocation ? 15 : 4}
          triggerClearSearch={triggerClearSearch}
        />

        {loading ? (
          <div className="uk-position-cover uk-overlay uk-overlay-primary uk-flex uk-flex-middle uk-flex-center">
            <div className="spinner">
              <UIKit.Spinner>
                Henter eiere av infrastruktur eller bygge- og anleggsarbeid
              </UIKit.Spinner>
            </div>
          </div>
        ) : (
          <div>
            {showMapDataOwners && (
              <div>
                <div className="uk-position-cover uk-overlay uk-overlay-primary uk-flex uk-flex-middle uk-flex-center"></div>
                <div className="uk-position-cover uk-flex uk-flex-middle uk-flex-center">
                  <UIKit.Card
                    size="small"
                    className="uk-width-large uk-width-1-1"
                  >
                    <UIKit.CardBody className="uk-padding-top">
                      <span className="uk-position-top-right uk-padding-small">
                        <UIKit.Button
                          onClick={handleClose}
                          className="uk-button uk-button-text"
                        >
                          <span
                            uk-icon="close"
                            uk-tooltip="Nullstill søk"
                          ></span>
                        </UIKit.Button>
                      </span>
                      {!mapObjects || mapObjects.length < 1 ? (
                        "Fant ingen treff i angitt område"
                      ) : (
                        <div>
                          <div>
                            <h3>
                              {mapObjects.length} eier(e) av {userSearchType}
                            </h3>
                          </div>
                          <ul
                            id="mapobjects"
                            className="uk-margin-top"
                            uk-accordion="true"
                          >
                            {mapObjects.map(
                              (ownerObj: IProductOwner, index: number) => (
                                <li key={index}>
                                  <a
                                    className="uk-link-reset uk-accordion-title"
                                    href=".#"
                                  >
                                    {ownerObj.name}
                                  </a>
                                  <ul className="uk-list uk-accordion-content">
                                    {ownerObj.phoneNumber && (
                                      <li>Mobil: {ownerObj.phoneNumber}</li>
                                    )}
                                    {ownerObj.email && (
                                      <li>
                                        E-post:{" "}
                                        <a href={`mailto:${ownerObj.email}`}>
                                          {ownerObj.email}
                                        </a>
                                      </li>
                                    )}
                                    <li>
                                      Adresse: {ownerObj.streetAddress}
                                      {ownerObj.zipCode &&
                                        `, ${ownerObj.zipCode}`}{" "}
                                      {ownerObj.postalPlace}
                                    </li>
                                    {ownerObj.websiteUrl && (
                                      <li>
                                        <a
                                          href={
                                            (ownerObj.websiteUrl.startsWith(
                                              "http"
                                            )
                                              ? ""
                                              : "https://") +
                                            ownerObj.websiteUrl
                                          }
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          Nettside
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </li>
                              )
                            )}
                          </ul>
                          <div className="uk-text-center">
                            <div className="uk-margin">
                              {fetchOrgDataByGeoLoading ? (
                                <div className="spinner">
                                  <UIKit.Spinner>Laster ned</UIKit.Spinner>
                                </div>
                              ) : (
                                <div>
                                  {userSearchType ===
                                    QueryTypes.Infrastructure && (
                                    <div className="uk-h5">
                                      Nedlastet liste vil vise ytterligere
                                      informasjon om infrastruktur, eksempelvis
                                      type tilgjengelig infrastruktur.
                                    </div>
                                  )}
                                  {userSearchType ===
                                    QueryTypes.Construction && (
                                    <div className="uk-h5">
                                      Nedlastet liste vil vise ytterligere
                                      informasjon om tiltaket, eksempelvis start
                                      og sluttdato for arbeidet.
                                    </div>
                                  )}
                                  {userSearchType === QueryTypes.FIP && (
                                    <div className="uk-h5">
                                      Nedlastet liste vil vise ytterligere
                                      informasjon om den ledige
                                      overføringskapasiteten
                                    </div>
                                  )}
                                  <UIKit.Button
                                    color="primary"
                                    onClick={handleDownloadSearchResult}
                                  >
                                    <span
                                      uk-icon="download"
                                      className="uk-margin-small-right"
                                    ></span>
                                    Last ned liste
                                  </UIKit.Button>
                                </div>
                              )}
                            </div>
                            <div>
                              <UIKit.Button
                                color="secondary"
                                onClick={handleClose}
                              >
                                Søk på nytt
                              </UIKit.Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </UIKit.CardBody>
                  </UIKit.Card>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default Search;
