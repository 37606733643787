import React from 'react';

export function Digger() {
  return (
    <span className="uk-icon">
        <svg width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="42.3639" cy="5.63636" r="3.63636" stroke="#3028AA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42 2L6.36363 12.5454L2 20.9091L8.90908 26.7272L11.8182 19.8182L39.4545 10.3636" stroke="#3028AA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M39 7L42 32.5" stroke="#3028AA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M46.0004 6V39.8181L43.0913 43.0909H28.5" stroke="#3028AA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M32 43L46 28.5" stroke="#3028AA" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </span>
  )
}