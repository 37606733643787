import { IImportJob, IImportJobRequest } from "types/IImportJob";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Fetches importjobs for an organisation.
 * @param orgNumber org number of the organisation.
 * @param token oidc access token.
 */
async function getAllImportJobs(
  orgNumber: string,
  isInfrastructure: boolean,
  token: string
): Promise<IImportJob[]> {
  const url = baseUrl.concat(
    `/api/import/byOrg/${orgNumber}?objectType=${
      isInfrastructure ? "Product" : "Construction"
    }`
  );
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Update all fields on the given organisation object
 * @param access_token bearer access token
 * @param importJob importjob to create
 */
async function postImportJob(
  access_token: string,
  importJobRequest: IImportJobRequest
): Promise<string> {
  const response = await fetch(baseUrl.concat("/api/import"), {
    method: "post",
    body: JSON.stringify(importJobRequest),
    headers: new Headers({
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    }),
  });

  return await response.json();
}

/**
 * cancel importjob on the given organisation object and importid
 * @param access_token bearer access token
 * @param importJobId id of the import job to cancel
 * @param orgNr org number of the organisation
 */
async function cancelImportJob(
  access_token: string,
  importJobId: string,
  orgNr: string
): Promise<string> {
  const response = await fetch(
    baseUrl.concat(`/api/import/${importJobId}/cancel?orgNr=${orgNr}`),
    {
      method: "put",
      headers: new Headers({
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      }),
    }
  );

  if (!response.ok) throw new Error(await response.text());
  return await response.text();
}

export { getAllImportJobs, postImportJob, cancelImportJob };
