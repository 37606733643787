import { UIKit } from '@egdeconsulting/ekom_lib';
import React from 'react';
import { InfrastructureType, InfrastructureTypeMappedToDesc, getEnumKeyByValue } from './IProduct';

const InfrastructureTypesWithDescription: InfrastructureTypeMappedToDesc[] = [
    {
        type: InfrastructureType.NkomStolpe,
        description: "Stolpe i eksempelvis lavspentnett, telenett eller gatebelysning."
    },
    {
        type: InfrastructureType.NkomTårn,
        description: "Tårn, master og lignende som kan egne seg for fremføring av høyhastighetsnett."
    },
    {
        type: InfrastructureType.NkomKum,
        description: "Trekkekum og andre typer kummer."
    },
    {
        type: InfrastructureType.NkomKabinett,
        description: "Skap eller tilsvarende for koblinger og utstyr."
    },
    {
        type: InfrastructureType.NkomKulvert,
        description: "Nedgravd tunnel eller bru laget for gjennomføring av kabler og annet teknisk utstyr."
    },
    {
        type: InfrastructureType.NkomFøringsvei,
        description: "Rør (trekkerør, avløpsrør etc), kabelgater og andre trasétyper som kan brukes for fremføring av høyhastighetsnett."
    },
    {
        type: InfrastructureType.NkomKapasitet,
        description: "Ledig fiberkapasitet som kan lånes bort, leies ut eller selges."
    },
]

type TypeSelectorViewProps = {
    activeType?: InfrastructureType;
    handleTypeChange: Function;
}
/**
 *
 * @param activeType: InfrastructreType | undefined. Determines which radio button is checked. If undefined, none is checked.
 * @param handleTypeChange: Function. Function triggered when a radio button is clicked.
 */
export function TypeSelectorView(props: TypeSelectorViewProps) {
    return (
            <UIKit.Section>
                <UIKit.Container>
                    <h2 className='uk-text-center'>Hva slags infrastruktur skal du registrere?</h2>
                    <div className='uk-padding uk-padding-remove-bottom'>
                        <p className="uk-margin-medium-bottom">Velg én type. Du kan registrere flere elementer av samme type samtidig.</p>
                        {Object.values(InfrastructureTypesWithDescription).map((product: InfrastructureTypeMappedToDesc, index: number) => (
                                <div key={index} className="uk-flex uk-flex-top">
                                    <div className="uk-display-inline-block uk-margin-small-right">
                                        <input
                                            id={product.type}
                                            required
                                            type="radio"
                                            className="uk-radio"
                                            name="type"
                                            onChange={(e) => props.handleTypeChange(e)}
                                            checked={props.activeType === getEnumKeyByValue(InfrastructureType, product.type)}
                                            value={getEnumKeyByValue(InfrastructureType, product.type)} />
                                    </div>
                                    <div className="uk-display-inline-block">
                                        <label htmlFor={product.type} className='uk-h4 uk-margin-remove-bottom'>{product.type}</label>
                                        <p className='uk-margin-remove-top'>{product.description}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </UIKit.Container>
            </UIKit.Section>
    )
}