import React, { useEffect } from "react";
import "./App.css";
import "./index.scss";
import { Root } from "./common/layout/containers/root.view";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./common/auth/containers/login";
import Logout from "./common/auth/containers/logout";
import PrivateRoute from "./common/auth/containers/privateRoute";
import Landing from "./common/containers/landing";
import Search from "./search/containers/search";
import FileImport from "./import/containers/fileImport";
import Export from "./export/containers/export";
import Role from "./common/role/containers/role";
import { setActiveRole } from "./common/user/user.action";
import { connect } from "react-redux";
import Profile from "./profile/containers/profile";
import ManualInfrastructure from "./import/containers/manualInfrastructure";
import ManualConstruction from "./import/containers/manualConstruction";
import ManualConstructionEdit from "./edit/containers/manualConstructionEdit";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./common/utilities/appInsights";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";

const App = () => {
  const dispatch = useDispatch();
  let history = createBrowserHistory();

  useEffect(() => {
    const activeRole = JSON.parse(
      sessionStorage.getItem("active_role") || "{}"
    );
    dispatch(setActiveRole(activeRole));
  }, [dispatch]);

  return (
    <Router>
      <Root>
        <Routes>
          <Route path="/" element={<PrivateRoute element={Landing} />} />
          <Route path="/role/*" element={<PrivateRoute element={Role} />} />
          <Route path="/profile" element={<PrivateRoute element={Profile} />} />
          <Route
            path="/exportdelete"
            element={<PrivateRoute element={Export} />}
          />
          <Route
            path="/infrastructure-file-import"
            element={<PrivateRoute element={FileImport} />}
          />
          <Route
            path="/construction-file-import"
            element={<PrivateRoute element={FileImport} />}
          />
          <Route
            path="/manual-infrastructure-import"
            element={<PrivateRoute element={ManualInfrastructure} />}
          />
          <Route
            path="/manual-construction-import"
            element={<PrivateRoute element={ManualConstruction} />}
          />
          <Route
            path="/manual-construction-edit"
            element={<PrivateRoute element={ManualConstructionEdit} />}
          />

          <Route
            path="/map-search"
            element={<PrivateRoute element={Search} />}
          />
          <Route path="/login/*" element={<Login myLocation={history} />} />
          <Route path="/logout/*" element={<Logout />} />
        </Routes>
      </Root>
    </Router>
  );
};

export default withAITracking(reactPlugin, connect()(App));
