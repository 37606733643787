import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, completeLogin } from "../auth.actions";
import isAuthenticated from "../isAuthenticated";
import { State } from "../../../redux/rootReducer";
import { Navigate, useLocation } from "react-router";
import { UIKit } from "@egdeconsulting/ekom_lib";

const Login = (props: { myLocation: any }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  const userAuthenticated = useSelector((state: State) =>
    isAuthenticated(state.auth.user)
  );
  //const error = useSelector((state: State) => state.auth.error);

  useEffect(() => {
    if (location.pathname === "/login/response") {
      dispatch<any>(completeLogin());
    } else if (!userAuthenticated) {
      localStorage.setItem("state", props.myLocation.location.pathname);
      dispatch<any>(login());
    }
  }, [
    location.pathname,
    dispatch,
    props.myLocation.location.pathname,
    userAuthenticated,
  ]);

  let from = sessionStorage.getItem("location") || "/";
  if (userAuthenticated) {
    return <Navigate to={from} />;
  } else {
    return (
      <UIKit.Container>
        <UIKit.Spinner>Logger inn</UIKit.Spinner>
      </UIKit.Container>
    );
  }
};

export default Login;
