import React from 'react';

export function Pencil() {
  return (
    <span className="uk-icon">        
        <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.00752 31.1729L34.1805 2L42.6015 10.4211L13.5 39.5L2 42L5.00752 31.1729Z" stroke="#3028AA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.00781 31.173L13.1281 39.2933" stroke="#3028AA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.2725 6.21057L38.3928 14.3309" stroke="#3028AA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </span>
  )
}