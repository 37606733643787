import { useMap } from "react-leaflet";

export const MyPositionControl = (props: { lat: number; lng: number }) => {
  const mapRef = useMap();

  const handleOnClickGoToUserLocation = () => {
    const zoomLevel = mapRef ? mapRef.getMaxZoom() - 1 : 0;
    mapRef.flyTo([props.lat, props.lng], zoomLevel);
  };

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control leaflet-bar">
        <button
          id="my-location"
          title="Gå til min posisjon"
          onClick={() => handleOnClickGoToUserLocation()}
        ></button>
      </div>
    </div>
  );
};
