import { combineReducers } from "redux";
import auth, { AuthState } from "../common/auth/auth.reducer";
import content, { ContentState } from "../content/content.reducer";
import mapData, { mapDataState } from "../common/map/mapSearch.reducer";
import importProduct, { ImportState } from "../import/import.reducers";
import limits, { LimitsState } from "../limits/limits.reducers";
import organisation, {
  OrganisationState,
} from "../common/organisation/organisation.reducers";
import { NotificationState, notification } from "@egdeconsulting/ekom_lib";
import exportProducts, { ExportState } from "../export/export.reducers";
import user, { UserState } from "../common/user/user.reducer";
import updateProduct, { UpdateState } from "../update/update.reducers";
import deleteProduct, { DeleteState } from "../delete/delete.reducers";

export type State = {
  auth: AuthState;
  content: ContentState;
  mapData: mapDataState;
  importProduct: ImportState;
  limits: LimitsState;
  organisation: OrganisationState;
  notification: NotificationState;
  exportProducts: ExportState;
  user: UserState;
  updateProduct: UpdateState;
  deleteProduct: DeleteState;
};

export default combineReducers<State>({
  auth,
  content,
  mapData,
  importProduct,
  limits,
  organisation,
  notification,
  exportProducts,
  user,
  updateProduct,
  deleteProduct,
});
