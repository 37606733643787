import {
  UPDATE_USER_BEGIN,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  FETCH_LOGGED_IN_USER_BEGIN,
  FETCH_LOGGED_IN_USER_FAILURE,
  FETCH_LOGGED_IN_USER_SUCCESS,
  FETCH_USER_ROLES_BEGIN,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_SUCCESS,
  SET_ACTIVE_ROLE,
  UNAUTHORIZED_ROLE,
} from "./user.action";
import { IUser, IUserRoles } from "./IUser";

export type UserState = {
  user?: IUser;
  fetchUserLoading: boolean;
  fetchUserError?: string;

  updateUserLoading: boolean;
  updateUserError?: string;
  updatedConsentSuccessfully?: boolean;

  roles?: IUserRoles[];
  fetchRolesLoading: boolean;
  fetchRolesError?: string;

  activeRole?: IUserRoles;
  unAuthorizedRoleError?: string;
};

export default function userReducer(
  state: UserState = {
    updateUserLoading: false,
    fetchUserLoading: false,
    fetchRolesLoading: false,
  },
  action: any
) {
  switch (action.type) {
    case FETCH_LOGGED_IN_USER_BEGIN:
      return {
        ...state,
        fetchUserLoading: true,
        fetchUserError: undefined,
      };
    case FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        fetchUserLoading: false,
        user: action.payload,
      };
    case FETCH_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        fetchUserLoading: false,
        fetchUserError: action.payload,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        updateUserLoading: true,
        updateUserError: undefined,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updatedConsentSuccessfully: state.user?.hasAcceptedNewestConsent,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserLoading: false,
        updateUserError: action.payload,
      };
    case FETCH_USER_ROLES_BEGIN:
      return {
        ...state,
        fetchRolesLoading: true,
        fetchRolesError: undefined,
      };
    case FETCH_USER_ROLES_SUCCESS:
      sessionStorage.setItem(
        "active_user_name",
        action.payload.find((r: { type: string }) => r.type === "Person").name
      );

      var sessionStorageActiveRole = JSON.parse(
        sessionStorage.getItem("active_role") || "{}"
      );
      let sessionStorageRoleStillValid;
      if (Object.keys(sessionStorageActiveRole).length > 0) {
        sessionStorageRoleStillValid = action.payload.find(
          (role: IUserRoles) =>
            role.id === sessionStorageActiveRole?.id &&
            role.type === sessionStorageActiveRole.type
        );
        if (!sessionStorageRoleStillValid)
          sessionStorage.removeItem("active_role");
      }
      return {
        ...state,
        fetchRolesLoading: false,
        roles: action.payload,
        activeRole: sessionStorageRoleStillValid ? state.activeRole : undefined,
      };
    case FETCH_USER_ROLES_FAILURE:
      sessionStorage.removeItem("active_role");
      return {
        ...state,
        fetchRolesLoading: false,
        fetchRolesError: action.payload,
        activeRole: undefined,
      };
    case SET_ACTIVE_ROLE:
      sessionStorage.setItem("active_role", JSON.stringify(action.payload));
      return {
        ...state,
        activeRole: action.payload,
      };
    case UNAUTHORIZED_ROLE:
      return {
        ...state,
        unAuthorizedRoleError: action.payload,
      };
    default:
      return state;
  }
}
