/**
 * Converts Date object to formatted string (dd.mm.yyyy)
 * @param date Date object
 * @returns {string}
 */
export function getDateStringFormatted(date)
{
    if(date !== undefined)
    {
        let tmpDate = new Date(date);
        let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(tmpDate);
        let month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(tmpDate);
        let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(tmpDate);
        return day.toString() + "." + month.toString() + "." + year.toString();
    }
    return "";
}