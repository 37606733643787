import { IUser, IUserRoles } from "./IUser";

export const FETCH_LOGGED_IN_USER_BEGIN = 'FETCH_LOGGED_IN_USER_BEGIN';
export const FETCH_LOGGED_IN_USER_SUCCESS = 'FETCH_LOGGED_IN_USER_SUCCESS'
export const FETCH_LOGGED_IN_USER_FAILURE = 'FETCH_LOGGED_IN_USER_FAILURE';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const FETCH_USER_ROLES_BEGIN = 'FETCH_USER_ROLES_BEGIN';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILURE = 'FETCH_USER_ROLES_FAILURE';

export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const UNAUTHORIZED_ROLE = 'UNAUTHORIZED_ROLE'

export const fetchLoggedInUserBegin = () => ({
  type: FETCH_LOGGED_IN_USER_BEGIN
});
export const fetchLoggedInUserSuccess = (user: IUser) => ({
  type: FETCH_LOGGED_IN_USER_SUCCESS,
  payload: user
});
export const fetchLoggedInUserFailure = (error: string, exception: Error) => ({
  type: FETCH_LOGGED_IN_USER_FAILURE,
  payload: { message: error, detail: exception.message }
})

export const updateUserBegin = () => ({
  type: UPDATE_USER_BEGIN
});
export const updateUserSuccess = (msg: string) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { message: msg }
});
export const updateUserFailure = (error: string, exception: Error) => ({
  type: UPDATE_USER_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const fetchUserRolesBegin = () => ({
  type: FETCH_USER_ROLES_BEGIN
});
export const fetchUserRolesSuccess = (roles: IUserRoles[]) => ({
  type: FETCH_USER_ROLES_SUCCESS,
  payload: roles
});
export const fetchUserRolesFailure = (errorMsg: string, exception: Error) => ({
  type: FETCH_USER_ROLES_FAILURE,
  payload: { message: errorMsg, detail: exception.message }
});

export const setActiveRole = (role: IUserRoles) => ({
  type: SET_ACTIVE_ROLE,
  payload: role
});

export const unAuthorizedRole = (error: string, exception: Error) => ({
  type: UNAUTHORIZED_ROLE,
  payload: { message: error, detail: exception.message }
})