import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, completeLogout } from "../auth.actions";
import { Navigate, useLocation } from "react-router";
import { State } from "redux/rootReducer";

const logoutRedirect = process.env.REACT_APP_LOGOUT_REDIRECT_URL || "";

const Logout = () => {
  const location = useLocation();
  const error = useSelector((state: State) => state.auth.error);
  const awaitingLogout = useSelector(
    (state: State) => state.auth.awaitingLogout
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/logout/response") {
      dispatch<any>(completeLogout());
    } else {
      dispatch<any>(logout());
    }
  }, [dispatch, location.pathname]);

  if (error) {
    return <>{error}</>;
  } else {
    if (logoutRedirect === "") return <Navigate to={{ pathname: "/" }} />;
    else {
      if (awaitingLogout === false) {
        window.location.href = logoutRedirect;
        return <></>;
      } else return <></>;
    }
  }
};

export default Logout;
