import { IContent } from './IContent';

export const FETCH_CONTENTDATA_BEGIN = 'FETCH_CONTENTDATA_BEGIN';
export const FETCH_CONTENTDATA_SUCCESS = 'FETCH_CONTENTDATA_SUCCESS';
export const FETCH_CONTENTDATA_FAILURE = 'FETCH_CONTENTDATA_FAILURE';


export const fetchContentDataBegin = (contentId: number) => ({
    type: FETCH_CONTENTDATA_BEGIN,
    contentId: contentId
});
export const fetchContentDataSuccess = (content: IContent) => ({
    type: FETCH_CONTENTDATA_SUCCESS,
    payload: content
});
export const fetchContentDataFailure = (error: string, contentId: number, exception: Error) => ({
    type: FETCH_CONTENTDATA_FAILURE,
    payload: { message: error, detail: exception.message },
    contentId: contentId
});