export interface IImportJob {
  importId: string;
  organisationId: string;
  importType: ImportType;
  importObjectType: ImportObjectType;
  fileName: string;
  fileReference: string;
  importStatus: ImportStatus;
  description: string;
  indexImported: number;
  indexTotal: number;
  importCreated: string;
  importStarted: string;
  importCompleted: string;
}

export enum ImportType {
  Csv,
  Json,
  SosiPrikk,
  SosiGml,
  M2M,
}

export enum ImportObjectType {
  Product,
  Construction,
}

export enum ImportStatus {
  Queue = "Queue",
  Processing = "Processing",
  Canceled = "Canceled",
  Failed = "Failed",
  Completed = "Completed",
}

export interface IImportJobRequest {
  fileName: string;
  fileReference: string;
  orgNr: string;
  importObjectType: ImportObjectType;
}
