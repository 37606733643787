import { useMutation, useQuery } from "@tanstack/react-query";
import { getImportType } from "common/utilities/import";
import { useSelector } from "react-redux";
import { State } from "redux/rootReducer";
import { getSasToken, uploadFileToBlobStorage } from "requests/Upload";

const useGetSasToken = (
  fileFromUser: File | undefined,
  fileExtension: string | undefined,
  validFileExtensions: boolean
) => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );

  return useQuery({
    queryKey: ["sastoken", access_token],
    queryFn: () =>
      getSasToken(
        access_token,
        getImportType(fileExtension),
        organisation?.organisationNumber || ""
      ),
    enabled: !!organisation && !!fileFromUser && validFileExtensions,
  });
};

function useUploadFile(sasToken: string, file: File | undefined) {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useMutation({
    mutationKey: ["uploadfile", access_token],
    mutationFn: () => uploadFileToBlobStorage(sasToken, file), // Modify the mutationFn to return a Promise<unknown>
    onSuccess: () => {}, // Modify onSuccess to handle the successful upload
    onError: () => {}, // Modify onError to handle the error
  });
}

export { useGetSasToken, useUploadFile };
