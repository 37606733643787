/**
 * Validates if the provied email is a valid email.
 * @param email string email
 */
export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Validates if the provided url is a valid url or empty.
 * @param url string url
 */
export function validateUrl(url: string) {
  if (url.trim() === '')
    return true;

  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
}

/**
 * Validates if the provied zipcode is 4 digits.
 * @param zipCode string zipCode
 */
export function validateZipcode(zipCode: string) {
  const re = /^[0-9]{4}$/;
  return re.test(zipCode);
}

/**
 * Validates if the provied phonenumber is 8 digits.
 * @param phoneNumber string phoneNumber
 */
export function validatePhonenumber(phoneNumber: string) {
  const re = /^[0-9]{8}$/;
  return re.test(String(phoneNumber).toLowerCase());
}