import React, { ReactElement } from "react";
import {
  Header,
  Notification,
  NotificationItem,
  clearNotifications,
  removeNotification,
  Footer,
} from "@egdeconsulting/ekom_lib";
import { State } from "../../../redux/rootReducer";
import isAuthenticated from "../../auth/isAuthenticated";
import { useSelector, useDispatch } from "react-redux";

const personvernSite = process.env.REACT_APP_PERSONVERN_URL || "";
const kontaktSite = process.env.REACT_APP_KONTAKT_URL || "";
const liabilitySite = process.env.REACT_APP_NKOMANSVAR_URL || "";

export const Root = (prop: { children: ReactElement }) => {
  const notifications = useSelector(
    (state: State) => state.notification?.notifications
  );
  const userAuthenticated = useSelector((state: State) =>
    isAuthenticated(state.auth.user)
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);
  const activeUserName = useSelector(
    (state: State) => sessionStorage.getItem("active_user_name") || ""
  );

  const dispatch = useDispatch();
  const removeN = (current: NotificationItem) =>
    dispatch(removeNotification(current));
  const clearN = () => dispatch(clearNotifications());

  return (
    <div>
      <Header
        title="EKOMPORTALEN"
        loggedIn={userAuthenticated}
        loggedInAsOrganisation={
          activeRole?.type && activeRole?.type === "Organization"
            ? activeRole.name
            : ""
        }
        loggedInName={activeUserName}
      />
      <Notification
        notifications={notifications}
        clearNotifications={clearN}
        removeNotification={removeN}
      />
      <div id="main">{prop.children}</div>
      <Footer
        links={[
          { link: personvernSite, linkText: "Personvern" },
          { link: kontaktSite, linkText: "Kontakt" },
          { link: liabilitySite, linkText: "Nkoms ansvar" },
        ]}
      />
    </div>
  );
};
