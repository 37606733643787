import { IOrganisation } from './IOrganisation';
import {
    fetchOrganisationByOrgNrBegin,
    fetchOrganisationByOrgNrFailure,
    fetchOrganisationByOrgNrSuccess,
    updateOrganisationBegin,
    updateOrganisationFailure,
    updateOrganisationSuccess,
    fetchOrganisationAPIKeyBegin,
    fetchOrganisationAPIKeyFailure,
    fetchOrganisationAPIKeySuccess,
    fetchOrganisationByOrgNrSuccessWithWarning,
    generateOrganisationAPIKeyBegin,
    generateOrganisationAPIKeyFailure,
    generateOrganisationAPIKeySuccess
} from './organisation.actions';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieves IOrganisation with provided orgNr
 * @param access_token string access bearer token
 * @param orgNr string orgnr for organisation to retrieve
 */
export function getOrganisationByOrgNr(access_token: string, orgNr: string, checkForContactInformation: boolean) {
    return (dispatch: any) => {
        dispatch(fetchOrganisationByOrgNrBegin());
        return fetch(baseUrl.concat('/api/organisation/' + orgNr), {
            headers: new Headers({ Authorization: 'Bearer ' + access_token })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
                else return await response.json()
            })
            .then((organisation: IOrganisation) => {
                if (checkForContactInformation && !organisation.contactInformation)
                    dispatch(fetchOrganisationByOrgNrSuccessWithWarning(organisation));
                else
                    dispatch(fetchOrganisationByOrgNrSuccess(organisation));

                return organisation;
            })
            .catch((error: Error) => dispatch(fetchOrganisationByOrgNrFailure(NotificationMessages.ORGANISATION_BY_ORGNR_FAILED.FETCH, error)));
    }
}

/**
 * Update all fields on the given organisation object
 * @param access_token bearer access token
 * @param organisation organisation to update
 */
export function updateOrganisation(access_token: string, organisation: IOrganisation) {
    return (dispatch: any) => {
        dispatch(updateOrganisationBegin());
        return fetch(baseUrl.concat('/api/organisation/'), {
            method: 'post',
            body: JSON.stringify([organisation]),
            headers: new Headers({
                Authorization: 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
            })
            .then(() => {
                dispatch(updateOrganisationSuccess(NotificationMessages.UPDATEORGANISATION_SUCCESS.FETCH));
            })
            .catch((error: Error) => {
                dispatch(updateOrganisationFailure(NotificationMessages.UPDATEORGANISATION_FAILED.FETCH, error))
            });
    }
}

/**
 * Retrieves API Key for provided organisation
 * @param access_token bearer access token
 * @param orgNr organisation to retrieve API token for
 */
export function getOrganisationAPIKey(access_token: string, orgNr: string) {
    return (dispatch: any) => {
        dispatch(fetchOrganisationAPIKeyBegin());
        return fetch(baseUrl.concat('/api/organisation/APIKey/' + orgNr), {
            headers: new Headers({ Authorization: 'Bearer ' + access_token })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
                else return await response.json()
            })
            .then((APIKey: string) => {
                dispatch(fetchOrganisationAPIKeySuccess(APIKey))
                return APIKey;
            })
            .catch((error: Error) => dispatch(fetchOrganisationAPIKeyFailure(NotificationMessages.ORGANISATION_APIKEY_FAILED.FETCH, error)))
    }
}

/**
 * Generates and retreives a new API Key for provided organisation
 * @param access_token bearer access token
 * @param orgNr organisation to retrieve API token for
 */
 export function postGenerateOrganisationAPIKey(access_token: string, orgNr: string) {
    return (dispatch: any) => {
        dispatch(generateOrganisationAPIKeyBegin());
        return fetch(baseUrl.concat('/api/organisation/GenerateAPIKey/' + orgNr), {
            method: 'POST',
            headers: new Headers({ Authorization: 'Bearer ' + access_token })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
                else return await response.json()
            })
            .then((APIKey: string) => {
                dispatch(generateOrganisationAPIKeySuccess(APIKey, NotificationMessages.ORGANISATION_GENERATE_APIKEY_SUCCESS.FETCH))
                return APIKey;
            })
            .catch((error: Error) => dispatch(generateOrganisationAPIKeyFailure(NotificationMessages.ORGANISATION_GENERATE_APIKEY_FAILED.FETCH, error)))
    }
}