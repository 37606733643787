export const POST_UPDATE_MANUAL_CONSTRUCTION_BEGIN = 'POST_UPDATE_MANUAL_CONSTRUCTION_BEGIN';
export const POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESS = '';
export const POST_UPDATE_MANUAL_CONSTRUCTION_FAILURE = 'POST_UPDATE_MANUAL_CONSTRUCTION_FAILURE';
export const RESET_POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESSFUL = 'RESET_POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESSFUL';

export const postUpdateManualConstructionBegin = () => ({
    type: POST_UPDATE_MANUAL_CONSTRUCTION_BEGIN
})
export const postUpdateManualConstructionSuccess = () => ({
    type: POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESS
})
export const postUpdateManualConstructionFailure = (error: string, exception: Error) => ({
    type: POST_UPDATE_MANUAL_CONSTRUCTION_FAILURE,
    payload: { message: error, detail: exception.message }
})
export const resetUpdateManualConstructionSuccessful = () => ({
    type: RESET_POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESSFUL
})