import {
    FETCH_LIMITS_BEGIN,
    FETCH_LIMITS_FAILURE,
    FETCH_LIMITS_SUCCESS
  } from './limits.actions';
  import { ILimits } from './ILimits';


export type LimitsState = {
    limits?: ILimits;
    fetchLimitsLoading: boolean;
    fetchLimitsError?: string;
  };
  
export default function limitsReducer(
    state: LimitsState = {
      fetchLimitsLoading: false
    },
    action: any
  ) {
    switch (action.type) {
        case FETCH_LIMITS_BEGIN:
            return {
                ...state,
                fetchLimitsLoading: true,
                fetchLimitsError: undefined
            };
        case FETCH_LIMITS_SUCCESS:
            return {
                ...state,
                fetchLimitsLoading: false,
                limits: action.payload
            };
        case FETCH_LIMITS_FAILURE:
            return {
                ...state,
                fetchLimitsLoading: false,
                fetchLimitsError: action.payload
            };
        default:
      return state;
    }
}