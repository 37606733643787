export interface IProduct {
    id?: string;
    name?: string;
    type?: any;
    ownerId?: number;
    status?: any;
    registrationType?: string;
    externalId?: string;
    externalNamespace?: string;
    deleted?: boolean;
    createdDate?: Date;
    geometry?: IGeometry;
    startDate?: Date;
    endDate?: Date;
}
interface IGeometry {
    type: string;
    coordinates: number[];
}

export enum InfrastructureStatus {
    Eksisterende = "Eksisterende",
    Planlagt = "Planlagt",
    Fjernet = "Fjernet",
    Nedlagt = "Nedlagt",
    "Under Arbeid" = "Under Arbeid",
    Kondemnert = "Kondemnert"
}

export enum InfrastructureType {
    NkomFøringsvei = "Føringsvei",
    NkomKapasitet = "Kapasitet",
    NkomStolpe = "Stolpe",
    NkomTårn = "Tårn",
    NkomKum = "Kum",
    NkomKabinett = "Kabinett",
    NkomKulvert = "Kulvert"
}

export interface InfrastructureTypeMappedToDesc {
    type: InfrastructureType,
    description: string
}

export enum ConstructionType {
    NkomOppføringAvBygning = "Oppføring av bygninger",
    NkomInnredningUtsmykkingsInstallasjonsArbeid = "Innrednings-, utsmykkings- og installasjonsarbeid",
    NkomMonteringDemonteringPrefabrikert = "Montering og demontering av prefabrikkerte elementer",
    NkomRivingDemonteringOmbyggingIstandsetting = "Riving, demontering, ombygging og istandsetting",
    NkomSaneringVedlikehold = "Sanering og vedlikehold",
    NkomAlminneligAnleggsvirksomhet = "Alminnelig anleggsvirksomhet",
    NkomGravingSprengningGrunnarbeid = "Graving, sprengning og annet grunnarbeid",
    NkomAnnetArbeid = "Annet arbeid som utføres i tilknytning til bygge- og anleggsarbeid"
}

export interface ConstructionTypeMappedToDesc {
    type: ConstructionType,
    description: string;
}

/**
 * Finds first key related to given value.
 * @param enumObject enum to find key in
 * @param enumValue Value mapped to searching key.
 */
export function getEnumKeyByValue(enumObject: any, enumValue: string) {
    return Object.keys(enumObject).find((p: any) => enumObject[p] === enumValue);
}

/**
 * Finds first key related to given value.
 * @param enumObject enum to find key in
 * @param enumValue Value mapped to searching key.
 */
 export function getEnumValueByKey(enumObject: any, enumKey: string) {
     return enumObject[enumKey];
}

export enum geometryTypes {
    Polyline,
    Point
}
export interface InfrastructureTypeManualRegistrationRules {
    type: InfrastructureType,
    legalGeometryType: geometryTypes,
    maxRegistrationAmount: number;
}

export const infrastructureTypeManualRegistrationRules: InfrastructureTypeManualRegistrationRules[] = [
    {
        type: InfrastructureType.NkomFøringsvei,
        legalGeometryType: geometryTypes.Polyline,
        maxRegistrationAmount: 10
    },
    {
        type: InfrastructureType.NkomKabinett,
        legalGeometryType: geometryTypes.Point,
        maxRegistrationAmount: 25
    },
    {
        type: InfrastructureType.NkomStolpe,
        legalGeometryType: geometryTypes.Point,
        maxRegistrationAmount: 25
    },
    {
        type: InfrastructureType.NkomTårn,
        legalGeometryType: geometryTypes.Point,
        maxRegistrationAmount: 25
    },
    {
        type: InfrastructureType.NkomKum,
        legalGeometryType: geometryTypes.Point,
        maxRegistrationAmount: 25
    },
    {
        type: InfrastructureType.NkomKapasitet,
        legalGeometryType: geometryTypes.Polyline,
        maxRegistrationAmount: 10
    },
    {
        type: InfrastructureType.NkomKulvert,
        legalGeometryType: geometryTypes.Polyline,
        maxRegistrationAmount: 10
    }
]