import React from "react";
import {
  IProduct,
  getEnumValueByKey,
  ConstructionType,
} from "../import/IProduct";
import { UIKit } from "@egdeconsulting/ekom_lib";
import InputMask from "react-input-mask";

type ConstructionEditRowViewProps = {
  product?: IProduct;
  productBeingEdited?: IProduct;
  startDate: {
    value: string;
    validationWarning?: string;
  };
  endDate: {
    value: string;
    validationWarning?: string;
  };
  handleInputChange: Function;
  handleDateInputChange: Function;
  handleSave: Function;
  handleAbortEdit: Function;
  showHeaders?: boolean;
};

export function ConstructionEditRowView(props: ConstructionEditRowViewProps) {
  return (
    <div>
      {props.showHeaders && (
        <UIKit.Grid
          options="margin:uk-margin-small;"
          match={true}
          className="uk-grid-small uk-child-width-1-6@m uk-flex uk-flex-top uk-flex-right uk-flex-between uk-text-left"
        >
          <div className="uk-text-bold uk-text-medium">Type</div>
          <div className="uk-text-bold uk-text-medium">Navn</div>
          <div className="uk-text-bold uk-text-medium">Startdato</div>
          <div className="uk-text-bold uk-text-medium">Sluttdato</div>
          <div className="uk-text-bold uk-text-medium">Status</div>
          <div>Handling</div>
        </UIKit.Grid>
      )}
      <UIKit.Grid
        options="margin:uk-margin-small;"
        match={true}
        className="uk-grid-small uk-child-width-1-6@m uk-flex uk-flex-top uk-flex-right uk-flex-between uk-text-left"
      >
        <div className="uk-overflow-hidden uk-text-break">
          <div className="uk-hidden@m uk-text-bold">Type</div>
          <span className="uk-text-muted">
            {getEnumValueByKey(
              ConstructionType,
              props.productBeingEdited?.type
            )}
          </span>
        </div>
        <div className="uk-overflow-hidden uk-text-break">
          <div className="uk-hidden@m uk-text-bold">Navn</div>
          {!props.productBeingEdited?.name ? (
            <span className="uk-text-italic uk-text-muted">
              Ikke spesifisert
            </span>
          ) : (
            <span className="uk-text-muted">
              {props.productBeingEdited?.name}
            </span>
          )}
        </div>
        <div>
          <div className="uk-hidden@m uk-text-bold">Startdato</div>
          <InputMask
            name="startDate"
            placeholder="dd.mm.åååå"
            mask="99.99.9999"
            value={props.startDate.value || ""}
            className={`uk-display-block uk-input uk-width-small ${
              props.startDate.validationWarning && "uk-form-danger"
            }`}
            onChange={props.handleDateInputChange}
          />
          {props.startDate.validationWarning && (
            <span className="uk-text-middle uk-text-danger uk-text-small">
              {props.startDate.validationWarning}
            </span>
          )}
        </div>
        <div>
          <div className="uk-hidden@m uk-text-bold">Sluttdato</div>
          <InputMask
            name="endDate"
            placeholder="dd.mm.åååå"
            mask="99.99.9999"
            value={props.endDate.value || ""}
            className={`uk-display-block uk-input uk-width-small ${
              props.endDate.validationWarning && "uk-form-danger"
            }`}
            onChange={props.handleDateInputChange}
          />
          {props.endDate.validationWarning && (
            <span className="uk-text-middle uk-text-danger uk-text-small">
              {props.endDate.validationWarning}
            </span>
          )}
        </div>
        <div className="">
          <div className="uk-hidden@m uk-text-bold">Handling</div>
          <div>
            {!props.startDate.validationWarning &&
            !props.endDate.validationWarning ? (
              <button
                className="uk-margin-right uk-button uk-button-primary"
                onClick={() => props.handleSave()}
              >
                Lagre
              </button>
            ) : (
              <button
                className="uk-margin-right uk-button uk-button-primary"
                disabled
              >
                Lagre
              </button>
            )}
            <button
              className="uk-button-link uk-button uk-margin-right"
              onClick={() => props.handleAbortEdit()}
            >
              Avbryt
            </button>
          </div>
        </div>
      </UIKit.Grid>
    </div>
  );
}
