import {
  postFileBegin,
  postFileFailure,
  postFileSuccess,
  postProductBegin,
  postProductFailure,
  postProductSucess,
} from "./import.actions";
import { NotificationMessages } from "@egdeconsulting/ekom_lib";
import { IProduct } from "./IProduct";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Post infrastructure or construction with csv or json file.
 * @param isInfrastructure boolean. If true query towards Products endpoint, else towards Construction endpoint
 * @param access_token bearer access token
 * @param file csv or json file
 * @param ownerId ownerId (in db) for the organisation which owns the provided infrastructure.
 */
export function postFile(
  isInfrastructure: boolean,
  access_token: string,
  file: File,
  ownerId: string
) {
  return (dispatch: any) => {
    dispatch(postFileBegin());

    var formDataBody = new FormData();
    let fileExtension = file?.name.split(".").pop()?.toLowerCase();
    if (fileExtension === "geojson") fileExtension = "json"; //same endpoint for .geojson and .json
    if (fileExtension === "gml") fileExtension = "sosgml";

    if (fileExtension === "csv") formDataBody.append("csvfile", file);
    else if (fileExtension === "json") formDataBody.append("jsonfile", file);
    else if (fileExtension === "sos") formDataBody.append("sosfile", file);
    else if (fileExtension === "sosgml")
      formDataBody.append("sosgmlfile", file);
    else
      dispatch(
        postFileFailure(
          NotificationMessages.FILEIMPORT_FAILED.FETCH,
          new Error("Illegal fileextension")
        )
      );

    const endpoint = isInfrastructure ? "Products" : "Construction";

    return fetch(
      baseUrl.concat(
        "/api/" + endpoint + "/import/" + fileExtension + "?ownerId=" + ownerId
      ),
      {
        method: "post",
        body: formDataBody,
        headers: new Headers({
          Authorization: "Bearer " + access_token,
          Accept: "application/json",
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
      })
      .then(() =>
        dispatch(
          postFileSuccess(NotificationMessages.FILEIMPORT_SUCCESS.FETCH, "")
        )
      )
      .catch((error: Error) =>
        dispatch(
          postFileFailure(NotificationMessages.FILEIMPORT_FAILED.FETCH, error)
        )
      );
  };
}
/**
 * Post product object
 * @param isInfrastructure boolean. If true query towards Products endpoint, else towards Construction endpoint
 * @param access_token string bearer access token
 * @param products array of IProduct
 */
export function postProducts(
  isInfrastructure: boolean,
  access_token: string,
  products: IProduct[],
  organisationNumber: string
) {
  return (dispatch: any) => {
    dispatch(postProductBegin());

    const endpoint = isInfrastructure ? "Products" : "Construction";
    return fetch(
      baseUrl.concat(
        "/api/" + endpoint + "/import/manual?ownerId=" + organisationNumber
      ),
      {
        method: "post",
        body: JSON.stringify(products),
        headers: new Headers({
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        }),
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
      })
      .then(() =>
        dispatch(
          postProductSucess(NotificationMessages.POST_PRODUCT_SUCCESS.POST)
        )
      )
      .catch((error: Error) =>
        dispatch(
          postProductFailure(
            NotificationMessages.POST_PRODUCT_FAILURE.POST,
            error
          )
        )
      );
  };
}
