import { GeoJsonObject } from 'geojson';
import { IProductOwner } from './models/IProductOwner'
import { IFreeTextSearch } from './models/IFreeTextSearch';
import { QueryTypes } from './models/QueryTypes';

export const FETCH_MAPOBJECTS_BEGIN = 'FETCH_MAPOBJECTS_BEGIN';
export const FETCH_MAPOBJECTS_SUCCESS = 'FETCH_MAPOBJECTS_SUCCESS';
export const FETCH_MAPOBJECTS_FAILURE = 'FETCH_MAPOBJECTS_FAILURE';
export const REMOVE_MAPOBJECTS = 'REMOVE_MAPOBJECTS';

export const CLEAR_FETCH_MAPOBJECTS_ERROR = 'CLEAR_FETCH_MAPOBJECTS_ERROR';

export const FETCH_POSTALLIMITS_BEGIN = 'FETCH_POSTALLIMITS_BEGIN';
export const FETCH_POSTALLIMITS_SUCCESS = 'FETCH_POSTALLIMITS_SUCCESS'
export const FETCH_POSTALLIMITS_FAILURE = 'FETCH_POSTALLIMITS_FAILURE';

export const FREETEXT_SEARCH_BEGIN = 'FREETEXT_SEARCH_BEGIN';
export const FREETEXT_SEARCH_SUCCESS = 'FREETEXT_SEARCH_SUCCESS';
export const FREETEXT_SEARCH_FAILURE = 'FREETEXT_SEARCH_FAILURE';

export const fetchMapObjectsDataBegin = () => ({
    type: FETCH_MAPOBJECTS_BEGIN
});
export const fetchMapObjectsDataSuccess = (ownerobjects: IProductOwner[], userSearchArea: any, userSearchType: QueryTypes) => ({
    type: FETCH_MAPOBJECTS_SUCCESS,
    payload: { owners: ownerobjects, userSearchArea: userSearchArea, userSearchType: userSearchType }
});
export const fetchMapObjectsDataFailure = (error: string, exception: Error) => ({
    type: FETCH_MAPOBJECTS_FAILURE,
    payload: { message: error, detail: exception.message }
});
export const removeProductOwnersData = () => ({
    type: REMOVE_MAPOBJECTS
});

export const clearFetchMapObjectsError = () => ({
    type: CLEAR_FETCH_MAPOBJECTS_ERROR
});

export const fetchPostalLimitsBegin = () => ({
    type: FETCH_POSTALLIMITS_BEGIN
});
export const fetchPostalLimitsSuccess = (postalLimits: GeoJsonObject) => ({
    type: FETCH_POSTALLIMITS_SUCCESS,
    payload: postalLimits
});
export const fetchPostalLimitsFailure = (error: string, exception: Error) => ({
    type: FETCH_POSTALLIMITS_FAILURE,
    payload: { message: error, detail: exception.message }
});

export const freeTextSearchBegin = () => ({
    type: FREETEXT_SEARCH_BEGIN
});
export const freeTextSearchSuccess = (searchResult: IFreeTextSearch[]) => ({
    type: FREETEXT_SEARCH_SUCCESS,
    payload: searchResult
});
export const freeTextSearchFailure = (error: string, exception: Error) => ({
    type: FREETEXT_SEARCH_FAILURE,
    payload: { message: error, detail: exception.message }
});