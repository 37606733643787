import { IProduct } from '../import/IProduct';
import {
  FETCH_ORG_DATA_BY_ORGNR_BEGIN,
  FETCH_ORG_DATA_BY_ORGNR_FAILURE,
  FETCH_ORG_DATA_BY_ORGNR_SUCCESS,
  FETCH_ORG_DATA_BY_GEO_BEGIN,
  FETCH_ORG_DATA_BY_GEO_FAILURE,
  FETCH_ORG_DATA_BY_GEO_SUCCESS,
  FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_BEGIN,
  FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_FAILURE,
  FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESS, 
  RESET_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESSFULL
} from './export.actions';

export type ExportState = {
  fetchOrgDataByOrgNrLoading: boolean;
  fetchOrgDataByOrgNrError?: string;
  productsByOrganisationIdFile?: File;
  fetchOrgDataByGeoLoading: boolean;
  fetchOrgDataByGeoError?: string;
  productsByGeoFile?: File;
  fetchManualConstructionsByOrgNrLoading: boolean;
  fetchManualConstructionsByOrgNrError?: string;
  fetchManualConstructionsByOrgNrSuccessfull: boolean;
  products: IProduct[];
}

export default function exportReducer(
  state: ExportState = {
    fetchOrgDataByOrgNrLoading: false,
    fetchOrgDataByGeoLoading: false,
    fetchManualConstructionsByOrgNrLoading: false,
    fetchManualConstructionsByOrgNrSuccessfull: false,
    products: []
  },
  action: any
) {
  switch (action.type) {
    case FETCH_ORG_DATA_BY_ORGNR_BEGIN:
      return {
        ...state,
        fetchOrgDataByOrgNrLoading: true,
        fetchOrgDataByOrgNrError: undefined
      };
    case FETCH_ORG_DATA_BY_ORGNR_SUCCESS:
      return {
        ...state,
        fetchOrgDataByOrgNrLoading: false,
        productsByOrganisationIdFile: action.payload
      }
    case FETCH_ORG_DATA_BY_ORGNR_FAILURE:
      return {
        ...state,
        fetchOrgDataByOrgNrLoading: false,
        fetchOrgDataByOrgNrError: action.payload
      }
    case FETCH_ORG_DATA_BY_GEO_BEGIN:
      return {
        ...state,
        fetchOrgDataByGeoLoading: true,
        fetchOrgDataByGeoError: undefined
      };
    case FETCH_ORG_DATA_BY_GEO_SUCCESS:
      return {
        ...state,
        fetchOrgDataByGeoLoading: false,
        productsByGeoFile: action.payload
      }
    case FETCH_ORG_DATA_BY_GEO_FAILURE:
      return {
        ...state,
        fetchOrgDataByGeoLoading: false,
        fetchOrgDataByGeoError: action.payload
      }
    case FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_BEGIN:
      return {
        ...state,
        fetchManualConstructionsByOrgNrLoading: true,
        fetchManualConstructionsByOrgNrSuccessfull: false,
        fetchManualConstructionsByOrgNrError: undefined
      };
    case FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESS:
        return {
          ...state,
          fetchManualConstructionsByOrgNrLoading: false,
          fetchManualConstructionsByOrgNrSuccessfull: true,
          fetchManualConstructionsByOrgNrError: undefined,
          products: action.payload.products
        };
    case FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_FAILURE:
      return {
        ...state,
        fetchManualConstructionsByOrgNrLoading: false,
        fetchManualConstructionsByOrgNrError: action.payload,
        products: []
      };
    case RESET_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESSFULL:
      return{
        ...state,
        fetchManualConstructionsByOrgNrSuccessfull: false
      }
    default:
      return state;
  }
}