import { GeoJsonObject } from 'geojson';
import { LatLngBounds } from 'leaflet';
import {
    fetchMapObjectsDataBegin,
    fetchMapObjectsDataSuccess,
    fetchMapObjectsDataFailure,
    fetchPostalLimitsBegin,
    fetchPostalLimitsFailure,
    fetchPostalLimitsSuccess,
    freeTextSearchBegin,
    freeTextSearchFailure,
    freeTextSearchSuccess
} from './mapSearch.actions'
import { NotificationMessages } from '@egdeconsulting/ekom_lib';
import { QueryTypes } from './models/QueryTypes';
import * as turf from '@turf/turf'

const baseUrl = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Fetches infrastructure data for the marked area
 * @param access_token access token from identity provider
 * @param geo geodata for marked area
 * @param queryType QueryTypes: Used to set userSearchType in state
 * @param typesToSearchFor: InfrastructureType[] which shall be queried
 */
export function getInfrastructureMapObjects(access_token: string, geo: any, queryType: QueryTypes, typesToSearchFor?: string[]) {
    if (geo.type === 'LineString') {
        let line = turf.lineString(geo.coordinates);
        geo = turf.buffer(line, 0.00065).geometry;
    }
    return (dispatch: any) => {
        dispatch(fetchMapObjectsDataBegin());
        return fetch(baseUrl.concat('/api/Products/query/bygeometry'), {
            method: 'post',
            body: JSON.stringify({ "geometry": geo, "typeFilter": typesToSearchFor || [] }),
            headers: new Headers({
                Authorization: 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
                else return await response.json()
            })
            .then(owners => {
                return dispatch(fetchMapObjectsDataSuccess(owners, geo, queryType));
            })
            .catch((error: Error) => dispatch(fetchMapObjectsDataFailure(NotificationMessages.MAPOBJECTS_FAILED.FETCH, error)));
    }
}

/**
 * Fetches construction data for the marked area
 * @param access_token access token from identity provider
 * @param geo geodata for marked area
 * @param queryType QueryTypes: Used to set userSearchType in state
 */
export function getConstructionMapObjects(access_token: string, geo: any, queryType: QueryTypes) {
    if (geo.type === 'LineString') {
        let line = turf.lineString(geo.coordinates);
        geo = turf.buffer(line, 0.00065).geometry;
    }
    return (dispatch: any) => {
        dispatch(fetchMapObjectsDataBegin());
        return fetch(baseUrl.concat('/api/Construction/query'), {
            method: 'post',
            body: JSON.stringify(geo),
            headers: new Headers({
                Authorization: 'Bearer ' + access_token,
                'Content-Type': 'application/json'
            })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(await response.text())
                }
                else return await response.json()
            })
            .then(owners => {
                return dispatch(fetchMapObjectsDataSuccess(owners, geo, queryType));
            })
            .catch((error: Error) => dispatch(fetchMapObjectsDataFailure(NotificationMessages.MAPOBJECTS_FAILED.FETCH, error)));
    }
}

/**
 * Service for retrieving postal limits
 * @param apiKey api key for retrieving post imits from norkart
 * @param bounds bounds to retrieve post limits for
 */
export function getPostalLimits(apiKey: string, bounds?: LatLngBounds) {
    return (dispatch: any) => {
        dispatch(fetchPostalLimitsBegin());
        return fetch('https://datavarehus.api.norkart.no/v2/views/sv_posten_55_postomraade/features/bboxquery?api_key=' + apiKey, {
            method: 'post',
            body: '{"bbox":"' + bounds?.getSouthWest().lng + ',' + bounds?.getSouthWest().lat + ',' + bounds?.getNorthEast().lng + ',' + bounds?.getNorthEast().lat + '"}',
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error()
                }
                else return await response.json()
            }).then((geoJsonObjects: GeoJsonObject) => {
                return dispatch(fetchPostalLimitsSuccess(geoJsonObjects));
            })
            .catch((error: Error) => dispatch(fetchPostalLimitsFailure(NotificationMessages.POSTALLIMITS_FAILED.FETCH, error)));
    }
}

/**
 * Queries Norkarts fritekstsøk/suggest API.
 * @param apiKey api key toward Norkarts FritektsSok
 * @param searchWord word to query
 */
export function freeTextSearch(apiKey: string, searchWord: string) {
    return (dispatch: any) => {
        dispatch(freeTextSearchBegin());
        return fetch('https://www.webatlas.no/WAAPI-FritekstSok/suggest/kommunecustom?Targets=postsone,stedsnavn,gateadresse&Query='
            + searchWord + '&api_key=' + apiKey, {
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error()
                }
                else return await response.json()
            })
            .then(searchResult => {
                return dispatch(freeTextSearchSuccess(searchResult.Options))
            })
            .catch((error: Error) => dispatch(freeTextSearchFailure(NotificationMessages.TEXTSEARCH_FAILED.FETCH, error)));
    }
}