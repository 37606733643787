import { BlockBlobClient } from "@azure/storage-blob";
import { ImportType } from "types/IImportJob";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Fetches sastoken for uploading file.
 * @param token oidc access token.
 */
async function getSasToken(
  token: string,
  importType: ImportType,
  orgNumber: string
): Promise<string> {
  const url = baseUrl.concat(
    `/api/import/token?importType=${importType.toLocaleString()}&orgNr=${orgNumber}`
  );
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

async function uploadFileToBlobStorage(
  sasToken: string,
  file: File | undefined
): Promise<string> {
  if (file === undefined) return "nofile";

  const blobber = new BlockBlobClient(sasToken);

  // Upload the file
  try {
    await blobber.uploadData(file, {
      blobHTTPHeaders: { blobContentType: file.type },
    });

    return "success";
  } catch (err) {
    return "error";
  }
}

export { getSasToken, uploadFileToBlobStorage };
