import React from "react";

export interface Step {
    text: string
}

type StepProps = {
    steps: Step[]
    activeStep: number
}
/**
 * Generic StepIndicator component
 * @param steps array of Step objects
 * @param activeStep index of current step
 */
export function StepIndicator(props: StepProps) {
        return (
            <ol className="uk-step-indicator uk-flex uk-child-width-expand">
                 {props.steps.map((step: Step, index: number) => {
                    return(
                        <li key={index} className={"uk-text-center uk-position-relative" + ( props.activeStep === index+1 ?' uk-active':'' )}>{step.text}</li>
                    )
                })}
            </ol>
        )
    }