import{
    POST_UPDATE_MANUAL_CONSTRUCTION_BEGIN,
    POST_UPDATE_MANUAL_CONSTRUCTION_FAILURE,
    POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESS,
    RESET_POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESSFUL
} from './update.actions';

export type UpdateState = {
    postUpdateManualConstructionLoading: boolean;
    postUpdateManualConstructionError?: string;
    postUpdateManualConstructionSuccessful: boolean;
}

export default function updateReducer(
    state: UpdateState = {
        postUpdateManualConstructionLoading: false,
        postUpdateManualConstructionSuccessful: false
    },
    action: any
) {
    switch(action.type){
        case POST_UPDATE_MANUAL_CONSTRUCTION_BEGIN:
            return{
                ...state,
                postUpdateManualConstructionLoading: true,
                postUpdateManualConstructionError: undefined
            };
        case POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESS:
            return{
                ...state,
                postUpdateManualConstructionLoading: false,
                postUpdateManualConstructionSuccessful: true,
                postUpdateManualConstructionError: undefined
            };
        case POST_UPDATE_MANUAL_CONSTRUCTION_FAILURE:
            return{
                ...state,
                postUpdateManualConstructionLoading: false,
                postUpdateManualConstructionError: action.payload.message
            };
        case RESET_POST_UPDATE_MANUAL_CONSTRUCTION_SUCCESSFUL:
            return{
                ...state,
                postUpdateManualConstructionSuccessful: false,
            }
        default:
            return state;
    }
}