import {
    FETCH_ORGANISATION_BY_ORGNR_BEGIN,
    FETCH_ORGANISATION_BY_ORGNR_FAILURE,
    FETCH_ORGANISATION_BY_ORGNR_SUCCESS,
    UPDATE_ORGANISATION_BEGIN,
    UPDATE_ORGANISATION_FAILURE,
    UPDATE_ORGANISATION_SUCCESS,
    FETCH_ORGANISATION_API_KEY_BEGIN,
    FETCH_ORGANISATION_API_KEY_FAILURE,
    FETCH_ORGANISATION_API_KEY_SUCCESS,
    FETCH_ORGANISATION_BY_ORGNR_SUCCESS_WITH_WARNING,
    GENERATE_ORGANISATION_API_KEY_BEGIN,
    GENERATE_ORGANISATION_API_KEY_FAILURE,
    GENERATE_ORGANISATION_API_KEY_SUCCESS
} from './organisation.actions'
import { IOrganisation } from './IOrganisation';

export type OrganisationState = {
    organisation?: IOrganisation;
    fetchOrganisationLoading: boolean;
    fetchOrganisationError?: string;

    updateOrganisationLoading: boolean;
    updateOrganisationError?: string;

    fetchOrganisationAPIKeyLoading: boolean;
    fetchOrganisationAPIKeyError?: string;
    organisationAPIKey?: string;

    generateOrganisationAPIKeyLoading: boolean;
    generateOrganisationAPIKeyError?: string;
}

export default function organisationReducer(
    state: OrganisationState = {
        fetchOrganisationLoading: false,
        updateOrganisationLoading: false,
        fetchOrganisationAPIKeyLoading: false,
        generateOrganisationAPIKeyLoading: false,
    },
    action: any
) {
    let organisationStateWithApiKey = state.organisation;
    switch (action.type) {
        case FETCH_ORGANISATION_BY_ORGNR_BEGIN:
            return {
                ...state,
                fetchOrganisationLoading: true,
                fetchOrganisationError: undefined
            };
        case FETCH_ORGANISATION_BY_ORGNR_SUCCESS:
        case FETCH_ORGANISATION_BY_ORGNR_SUCCESS_WITH_WARNING:
            return {
                ...state,
                fetchOrganisationLoading: false,
                organisation: action.payload
            };
        case FETCH_ORGANISATION_BY_ORGNR_FAILURE:
            return {
                ...state,
                fetchOrganisationLoading: false,
                fetchOrganisationError: action.payload
            };
        case UPDATE_ORGANISATION_BEGIN:
            return {
                ...state,
                updateOrganisationLoading: true,
                updateOrganisationError: undefined
            };
        case UPDATE_ORGANISATION_SUCCESS:
            return {
                ...state,
                updateOrganisationLoading: false
            };
        case UPDATE_ORGANISATION_FAILURE:
            return {
                ...state,
                updateOrganisationLoading: false,
                updateOrganisationError: action.payload
            };
        case FETCH_ORGANISATION_API_KEY_BEGIN:
            return {
                ...state,
                fetchOrganisationAPIKeyLoading: true,
                fetchOrganisationAPIKeyError: undefined
            };
        case FETCH_ORGANISATION_API_KEY_SUCCESS:
            if (organisationStateWithApiKey) organisationStateWithApiKey.APIKey = action.payload;
            return {
                ...state,
                fetchOrganisationAPIKeyLoading: false,
                organisationAPIKey: action.payload,
                organisation: organisationStateWithApiKey
            };
        case FETCH_ORGANISATION_API_KEY_FAILURE:
            return {
                ...state,
                fetchOrganisationAPIKeyLoading: false,
                fetchOrganisationAPIKeyError: action.payload
            };
        case GENERATE_ORGANISATION_API_KEY_BEGIN:
            return {
                ...state,
                generateOrganisationAPIKeyLoading: true,
                generateOrganisationAPIKeyError: undefined
            };
        case GENERATE_ORGANISATION_API_KEY_SUCCESS:
            return {
                ...state,
                generateOrganisationAPIKeyLoading: false,
                organisationAPIKey: action.payload.APIKey
            };
        case GENERATE_ORGANISATION_API_KEY_FAILURE:
            return {
                ...state,
                generateOrganisationAPIKeyLoading: false,
                generateOrganisationAPIKeyError: action.payload
            };
        default:
            return state;
    }
}