import React, { useEffect, useState } from "react";
import { handleHasRole } from "../utilities/roleHandler";
import * as Icons from "../../uikit/assets/svg/index";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { useSelector, useDispatch } from "react-redux";
import { ServiceCard } from "@egdeconsulting/ekom_lib";
import { hasRole } from "@egdeconsulting/ekom_lib";
import { getUserRoles } from "../user/user.service";
import { State } from "../../redux/rootReducer";

const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";
const QandAurl = process.env.REACT_APP_QANDA_URL || "0";
const InternalHostNames = process.env.REACT_APP_INTERNALHOSTNAMES || undefined;

export const Landing = () => {
  const dispatch = useDispatch();

  const [IsImportRole, setIsImportRole] = useState(false);

  const activeRole = useSelector((state: State) => state.user.activeRole);
  const accessToken = useSelector(
    (state: State) => state.auth.user && state.auth.user.access_token
  );
  const organization_name = useSelector(
    (state: State) => state.user.activeRole?.name || ""
  );
  const roles = useSelector((state: State) => state.user.roles);
  const rolesLoading = useSelector(
    (state: State) => state.user.fetchRolesLoading
  );

  useEffect(() => {
    if (accessToken) dispatch<any>(getUserRoles(accessToken));
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!!!roles) return;

    handleHasRole();
    setIsImportRole(
      !!activeRole && Object.keys(activeRole).length > 0
        ? hasRole(importRoleName, activeRole)
        : false
    );
  }, [roles, activeRole]);

  // //TODO
  // handleHasRole();
  // const isImportRole: boolean =
  //   !!activeRole && Object.keys(activeRole).length > 0
  //     ? hasRole(importRoleName, activeRole)
  //     : false;

  return !!!roles || rolesLoading ? (
    <UIKit.Spinner>Henter roller</UIKit.Spinner>
  ) : (
    <UIKit.Section>
      <UIKit.Container>
        <h1 className="uk-text-center">Hva vil du gjøre?</h1>
        <UIKit.Grid
          match={true}
          className="uk-child-width-1-3@m uk-child-width-1-2@s"
        >
          <div>
            <ServiceCard
              hover
              icon={<span uk-icon="icon: search; ratio: 2"></span>}
              link="map-search"
              linkText="Søk"
              title="Søke i kart"
            >
              <p className="uk-margin-medium-bottom uk-text-left">
                Søk eller velg et område i kart og finn eiere av infrastruktur
                som er egnet for høyhastighetsnett eller bygge- og
                anleggsarbeider.
              </p>
            </ServiceCard>
          </div>
          {IsImportRole && (
            <div>
              <ServiceCard
                hover
                icon={<Icons.Pencil />}
                link="infrastructure-file-import"
                linkText="Registrer"
                title={"Registrere infrastruktur for " + organization_name}
              >
                <p className="uk-margin-medium-bottom uk-text-left">
                  Registrer passiv infrastruktur som egner seg for fremføring av
                  høyhastighetsnett eller ledig fiberkapasitet som kan lånes
                  bort, leies ut eller selges.
                </p>
              </ServiceCard>
            </div>
          )}
          {IsImportRole && (
            <div>
              <ServiceCard
                hover
                icon={<span uk-icon="icon: list; ratio: 2"></span>}
                link="exportdelete"
                linkText="Last ned eller slett"
                title="Eksportere eller slette registrert infrastruktur eller bygge- og anleggsarbeid"
              >
                <p className="uk-margin-medium-bottom uk-text-left">
                  Eksporter eller slett {organization_name} sine data registrert
                  i Ekomportalen.
                </p>
              </ServiceCard>
            </div>
          )}
          {IsImportRole && (
            <div>
              <ServiceCard
                hover
                icon={<Icons.Digger />}
                link="construction-file-import"
                linkText="Registrere og redigere"
                title="Registrere og redigere bygge- og anleggsarbeider"
              >
                <p className="uk-margin-medium-bottom uk-text-left">
                  Registrere og redigere bygge- og anleggsarbeider.
                </p>
              </ServiceCard>
            </div>
          )}
          {IsImportRole && (
            <div>
              <ServiceCard
                hover
                icon={<Icons.Business />}
                link="profile"
                linkText="Se profil"
                title={"Se profil for " + organization_name}
              >
                <p className="uk-margin-medium-bottom uk-text-left">
                  Se og endre hvilken kontaktinformasjon til {organization_name}{" "}
                  som vises i Ekomportalen.
                </p>
              </ServiceCard>
            </div>
          )}
          <div>
            <ServiceCard
              hover
              icon={<span uk-icon="icon: comment; ratio: 2"></span>}
              link={QandAurl}
              linkText="Se spørsmål og svar"
              title="Se spørsmål og svar"
              internalHosts={
                InternalHostNames ? InternalHostNames.split(";") : undefined
              }
            >
              <p className="uk-margin-medium-bottom uk-text-left">
                Finn svar på vanlige spørsmål om Ekomportalen.
              </p>
            </ServiceCard>
          </div>
        </UIKit.Grid>
      </UIKit.Container>
    </UIKit.Section>
  );
};

export default Landing;
