import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';
import { composeWithDevTools } from '@redux-devtools/extension';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = createStore(rootReducer, composedEnhancer)
export default store;

// export default function configureStore() {
//   const middlewares = [thunkMiddleware]
//     const middlewareEnhancers = applyMiddleware(...middlewares)

//     //Only apply redux dev tools if the extension for the client exists.
//     const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
//         ? window.__REDUX_DEVTOOLS_EXTENSION__()
//         : f => f
//     const enhancers = [middlewareEnhancers, reduxDevTools]
//     const composedEnhancers = compose(...enhancers)

//     //no preloadedState. 
//     const store = createStore(rootReducer, {}, composedEnhancers)
//     return store
// }