import React, { useEffect, useState } from "react";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { State } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { TypeSelectorView } from "../typeSelector.view";
import { unAuthorizedRole } from "../../common/user/user.action";
import { hasRole, ModalView } from "@egdeconsulting/ekom_lib";
import { Navigate } from "react-router";
import {
  InfrastructureStatus,
  InfrastructureType,
  IProduct,
  infrastructureTypeManualRegistrationRules,
  getEnumKeyByValue,
} from "../IProduct";
import { RegisterInMapView } from "../registerInMap.view";
import { ProductListView } from "../ProductList.view";
import { ProductEditRowView } from "../productEditRow.view";
import { StepIndicator } from "../stepIndicator.view";
import { postProducts } from "../import.service";
import { getOrganisationByOrgNr } from "../../common/organisation/organisation.service";
import { resetPostProductSuccessfull } from "../import.actions";
import { useGeolocated } from "react-geolocated";

const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";

export const ManualInfrastructure = () => {
  const dispatch = useDispatch();

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) ?? ""
  );
  const postProductsLoading = useSelector(
    (state: State) => state.importProduct.postProductLoading
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);

  const fetchOrganisationLoading = useSelector(
    (state: State) => state.organisation.fetchOrganisationLoading
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );
  const fetchOrganisationError = useSelector(
    (state: State) => state.organisation.fetchOrganisationError
  );
  const postProductSuccessfull = useSelector(
    (state: State) => state.importProduct.postProductSuccessfull
  );

  const [wizardStep, setWizardStep] = useState(1);
  const [
    showConfirmationPreviousStepModal,
    setShowConfirmationPreviousStepModal,
  ] = useState(false);
  const [type, setType] = useState<InfrastructureType | undefined>(undefined);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productBeingEditedIndex, setProductBeingEditedIndex] = useState<
    number | undefined
  >(undefined);
  const [productBeingEdited, setProductBeingEdited] = useState<
    IProduct | undefined
  >(undefined);
  const [registeringNewProduct, setRegisteringNewProduct] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState<any>(undefined);
  const [showConfirmDeleteProductModal, setShowConfirmDeleteProductModal] =
    useState(false);
  const [productIndexToRemove, setProductIndexToRemove] = useState<
    number | undefined
  >(undefined);

  //const [editRow, setEditRow] = useState<HTMLDivElement | null>(null);
  const [newProductEditRowRef, setNewProductEditRowRef] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (postProductSuccessfull) {
      setWizardStep(1);
      setType(undefined);
      setProducts([]);
      setProductBeingEdited(undefined);
      setProductBeingEditedIndex(undefined);
      dispatch(resetPostProductSuccessfull());
    }
  }, [postProductSuccessfull, dispatch]);

  useEffect(() => {
    if (newProductEditRowRef) newProductEditRowRef.scrollIntoView();
  }, [newProductEditRowRef]);

  const handleInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    if (fieldName === "type") setType(value);
  };

  const handlePreviousClick = () => {
    if (wizardStep === 2) {
      setShowConfirmationPreviousStepModal(true);
      setRegisteringNewProduct(false);
      setProductBeingEdited(undefined);
      setSelectedLayer(undefined);
    } else setWizardStep(wizardStep - 1);
  };

  const handleSubmitPreviousStep = () => {
    setWizardStep(wizardStep - 1);
    setShowConfirmationPreviousStepModal(false);
    setProducts([]);
  };

  const handleCreatedDrawing = (e: any) => {
    var geometry = e.layer.toGeoJSON().geometry;
    var newProduct: IProduct = {
      geometry: geometry,
    };
    setTimeout(() => setProductBeingEdited(newProduct));
    setTimeout(() => setRegisteringNewProduct(true));
    setTimeout(() => setSelectedLayer(e.layer));
  };

  const handleProductInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;

    let tmpProductBeingEdited = productBeingEdited || {};

    if (fieldName === "name") tmpProductBeingEdited.name = value;
    if (fieldName === "status") tmpProductBeingEdited.status = value;
    if (tmpProductBeingEdited.status === undefined)
      tmpProductBeingEdited.status = InfrastructureStatus.Eksisterende;

    setProductBeingEdited(tmpProductBeingEdited);
  };

  const handleStartEditProduct = (productIndex: number) => {
    setProductBeingEditedIndex(productIndex);
    setProductBeingEdited({ ...products[productIndex] });
  };

  const handleAbortEdit = () => {
    setProductBeingEditedIndex(undefined);
    setProductBeingEdited(undefined);
  };

  const handleOnClickRemoveProduct = (productIndex: number) => {
    setProductIndexToRemove(productIndex);
    setShowConfirmDeleteProductModal(true);
  };

  const handleRemoveProduct = () => {
    let tmpProducts: IProduct[] = [...products];
    productIndexToRemove !== undefined &&
      tmpProducts.splice(productIndexToRemove, 1);
    setProducts(tmpProducts);
    setShowConfirmDeleteProductModal(false);
  };

  const handleSaveProduct = () => {
    if (productBeingEditedIndex !== undefined && productBeingEdited) {
      let tmpProducts = [...products];
      let tmpProductToUpdate = {
        ...tmpProducts[productBeingEditedIndex],
      };
      tmpProductToUpdate.name =
        productBeingEdited.name ?? tmpProductToUpdate.name;
      tmpProductToUpdate.status =
        productBeingEdited.status ?? tmpProductToUpdate.status;

      tmpProducts[productBeingEditedIndex] = tmpProductToUpdate;
      setProducts(tmpProducts);
      setProductBeingEditedIndex(undefined);
      setProductBeingEdited(undefined);
    }
  };

  const handleSaveNewProduct = () => {
    if (productBeingEdited) {
      selectedLayer.remove();
      let newProduct = { ...productBeingEdited };
      if (newProduct.status === undefined)
        newProduct.status = InfrastructureStatus.Eksisterende;

      setProducts(products.concat(newProduct));
      setProductBeingEdited(undefined);
      setRegisteringNewProduct(false);
      setSelectedLayer(undefined);
    }
  };

  const handleAbortSaveNewProduct = () => {
    selectedLayer.remove();
    if (productBeingEdited) {
      setProductBeingEdited(undefined);
      setRegisteringNewProduct(false);
      setSelectedLayer(undefined);
    }
  };

  const handleSubmit = (orgNr: string) => {
    let tmpProducts = [...products];
    tmpProducts.forEach((p) => (p.type = type));

    setProducts(tmpProducts);

    dispatch<any>(postProducts(true, access_token, products, orgNr));
  };

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      if (
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, true)
        );
      }
      return (
        <UIKit.Section>
          <div className="uk-container uk-padding-remove-horizontal">
            <div className="uk-padding-left uk-padding-right">
              <h1 className="uk-text-center">
                Registrer infrastruktur manuelt
              </h1>
              <p>
                Her kan du registrere infrastruktur manuelt, hvis du ikke har en
                CSV-, GeoJSON-, SOSI-, eller GML-fil å laste opp. Velg type
                infrastruktur som skal registreres og følg veilederen nedenfor
                for å plassere infrastrukturelementene i kartet.
              </p>
              <p>
                <a href="infrastructure-file-import">
                  Gå tilbake til registrering av infrastruktur ved filopplasting
                </a>
              </p>
            </div>
            <div className="uk-background-light-purple-tint">
              <div className="uk-padding-top">
                <StepIndicator
                  activeStep={wizardStep}
                  steps={[
                    { text: "Velg type" },
                    { text: "Plasser elementer i kart" },
                    { text: "Se over og bekreft" },
                  ]}
                />
              </div>
              {postProductsLoading && (
                <div className="uk-text-center">
                  <UIKit.Spinner>Registrerer elementer...</UIKit.Spinner>
                </div>
              )}
              {wizardStep === 1 && (
                <TypeSelectorView
                  activeType={type}
                  handleTypeChange={handleInputChange}
                />
              )}

              {wizardStep === 2 && type && (
                <div>
                  <RegisterInMapView
                    coords={coords}
                    handleOnCreatedDrawing={(e: any) => handleCreatedDrawing(e)}
                    showDrawButtons={productBeingEdited === undefined}
                    type={type}
                    numberOfRegisteredProducts={products.length}
                  />
                  {registeringNewProduct && (
                    <div
                      className="uk-padding-left@l uk-padding-right@l"
                      ref={setNewProductEditRowRef}
                    >
                      <div className="uk-padding-small uk-background-white">
                        <ProductEditRowView
                          productBeingEdited={productBeingEdited}
                          handleInputChange={(e: any) =>
                            handleProductInputChange(e)
                          }
                          handleSave={handleSaveNewProduct}
                          handleAbortEdit={handleAbortSaveNewProduct}
                          showHeaders={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {wizardStep === 3 && (
                <div>
                  <h2 className="uk-text-center">Bekreft registrering</h2>
                </div>
              )}
              {wizardStep === 2 || wizardStep === 3 ? (
                <div className="uk-padding-left@l uk-padding-right@l uk-margin">
                  {products.length > 0 && (
                    <div className="uk-text-medium uk-padding-small uk-padding-remove-top">
                      {products.length} stk.{" "}
                      {type &&
                        InfrastructureType[
                          type.toString() as keyof typeof InfrastructureType
                        ].toLowerCase()}{" "}
                      lagt til av maksimalt{" "}
                      {
                        infrastructureTypeManualRegistrationRules.find(
                          (i) =>
                            getEnumKeyByValue(InfrastructureType, i.type) ===
                            type
                        )?.maxRegistrationAmount
                      }
                    </div>
                  )}
                  <div className="uk-background-white uk-padding-small uk-padding-remove-top uk-padding-small-top@l">
                    <ProductListView
                      products={products}
                      handleEdit={handleStartEditProduct}
                      handleRemove={handleOnClickRemoveProduct}
                      productBeingEditedIndex={productBeingEditedIndex}
                      productBeingEdited={productBeingEdited}
                      handleInputChange={(e: any) =>
                        handleProductInputChange(e)
                      }
                      handleSave={handleSaveProduct}
                      handleAbortEdit={handleAbortEdit}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="uk-padding uk-flex uk-flex-between">
                <div className="uk-text-left">
                  {wizardStep > 1 && (
                    <UIKit.Button
                      color="secondary"
                      onClick={handlePreviousClick}
                      size="large"
                    >
                      Forrige
                    </UIKit.Button>
                  )}
                </div>

                {wizardStep < 3 && (
                  <div className="uk-text-right">
                    <UIKit.Button
                      disabled={
                        !organisation?.contactInformation ||
                        (wizardStep > 1 ? products.length <= 0 : !!!type)
                      }
                      color="secondary"
                      onClick={() => setWizardStep(wizardStep + 1)}
                      size="large"
                    >
                      Neste
                    </UIKit.Button>
                  </div>
                )}

                {wizardStep === 3 && (
                  <div className="uk-text-right">
                    {postProductsLoading ? (
                      <UIKit.Button disabled={true}>
                        <UIKit.Spinner>Registrerer...</UIKit.Spinner>
                      </UIKit.Button>
                    ) : (
                      <UIKit.Button
                        color="primary"
                        className="uk-box-sizing-border-box uk-button-large"
                        disabled={postProductsLoading || products.length <= 0}
                        onClick={() =>
                          activeRole && handleSubmit(activeRole.id)
                        }
                      >
                        <span>Bekreft registrering</span>
                      </UIKit.Button>
                    )}
                  </div>
                )}
              </div>
            </div>

            <ModalView
              show={showConfirmationPreviousStepModal}
              size="lg"
              modalTitle="Er du sikker på at du vil tilbake til steg 1?"
              onClose={() => setShowConfirmationPreviousStepModal(false)}
              onSubmit={() => handleSubmitPreviousStep()}
              submitButtonText={"Ja"}
              closeButtonText={"Nei, bli værende"}
            >
              <p className="uk-margin-small-top uk-text-center">
                Hvis du går tilbake vil pågående registrering avbrytes, og
                hittil registrert data går tapt.
              </p>
            </ModalView>

            <ModalView
              show={showConfirmDeleteProductModal}
              size="lg"
              modalTitle="Er du sikker på at du vil slette infrastrukturelementet?"
              onClose={() => setShowConfirmDeleteProductModal(false)}
              onSubmit={() => handleRemoveProduct()}
              submitButtonText={"Ja"}
              closeButtonText={"Nei"}
            >
              <p className="uk-margin-small-top uk-text-center">
                Hvis du sletter dette elementet vil det forsvinne fra
                registreringsprosessen.
              </p>
            </ModalView>
          </div>
        </UIKit.Section>
      );
    }
  } else {
    dispatch(
      unAuthorizedRole(
        "For å importere må du ha rollen: " + importRoleName,
        new Error("")
      )
    );
    return <Navigate to="/" />;
  }
};

export default ManualInfrastructure;
