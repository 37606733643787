
/**
 * Adds spacing in number.
 * @param number number to add whitespace in
 * @returns string representation of number with whitespaces
 * e.g. 
 *  1
    10
    100
    1 000
    10 000
    100 000
    1 000 000
    10 000 000
    100 000 000
    1 000 000 000
 */
export function addSpacingToNumber(number: number) : string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}