
import L from 'leaflet';

export function configureLeafletDrawLanguage() {
  L.drawLocal.draw.toolbar.actions.text = 'Avbryt';
  L.drawLocal.draw.toolbar.actions.title = 'Avbryt tegning';
  L.drawLocal.draw.toolbar.finish.text = 'Avslutt';
  L.drawLocal.draw.toolbar.finish.title = 'Avslutt å tegne';
  L.drawLocal.draw.toolbar.undo.text = 'Slett forrige punkt';
  L.drawLocal.draw.toolbar.undo.title = 'Slett forrige tegnede punkt';

  L.drawLocal.draw.toolbar.buttons.polyline = 'Tegn en linje';
  L.drawLocal.draw.handlers.polyline.tooltip.start = 'Klikk for å lage et punkt i linjen';

  L.drawLocal.draw.handlers.rectangle.tooltip.start = 'Klikk og dra for å søke med rektangel';
  L.drawLocal.draw.toolbar.buttons.rectangle = 'Tegn et rektangel';

  L.drawLocal.draw.toolbar.buttons.polygon = 'Tegn et polygon';
  L.drawLocal.draw.handlers.polygon.tooltip.start = 'Klikk for å lage et punkt i formen';

  L.drawLocal.draw.toolbar.buttons.marker = 'Marker et punkt';
  L.drawLocal.draw.handlers.marker.tooltip.start = 'Klikk i kartet for å plassere markør';
}

export function configureLeafletZoomLanguage() {
  var zoomOut = document.getElementsByClassName('leaflet-control-zoom-out')[0];
  var zoomIn = document.getElementsByClassName('leaflet-control-zoom-in')[0];
  zoomOut.setAttribute('title', 'Zoom ut')
  zoomIn.setAttribute('title', 'Zoom inn')
}

export function getTooltipStart(e) {
  return L['drawLocal']['draw']['handlers'][e.layerType]['tooltip']['start'] || ""
}