import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../../redux/rootReducer";
import { freeTextSearch } from "../mapSearch.service";
import { IFreeTextSearch } from "../models/IFreeTextSearch";

const apiKey =
  process.env.REACT_APP_MAP_BACKGROUND_API_KEY ||
  "d1b7f537-552c-49a1-a2b5-90f4169a5b1c";

type MapFreeTextSearchProps = {
  onClickSearchResult: (searchResult: IFreeTextSearch) => void;
};

export const MapFreeTextSearch = (props: MapFreeTextSearchProps) => {
  const dispatch = useDispatch();
  const freeTextSearchResult = useSelector(
    (state: State) => state.mapData.freeTextSearchResult
  );
  // const freeTextSearchLoading = useSelector(
  //   (state: State) => state.mapData.freeTextSearchLoading
  // );

  const [searchValue, setSearchValue] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.length >= 2) {
      dispatch<any>(freeTextSearch(apiKey, value));
    }

    setShowSearchResults(value.length >= 2);
  };

  const handleOnClickSearchResult = (searchResult: IFreeTextSearch) => {
    props.onClickSearchResult(searchResult);
    setSearchValue(searchResult.Text);
    setShowSearchResults(false);
  };

  return (
    <div
      className={`uk-position-relative map-search ${
        showSearchResults &&
        freeTextSearchResult &&
        searchValue &&
        "show-search-list"
      }`}
    >
      <div className="uk-h4 title">Kartsøk</div>
      <label className="sr-only">Kartsøk</label>
      <div className="uk-inline uk-width-1-1">
        <span
          className="uk-text-light-purple uk-form-icon uk-form-icon-flip"
          uk-icon="icon: search"
        ></span>
        <label className="sr-only" htmlFor="map-search">
          Skriv inn sted, postnummer eller adresse
        </label>
        <input
          id="map-search"
          autoComplete="off"
          type="text"
          name="SearchInput"
          className="uk-input"
          onChange={(e) => handleChange(e)}
          value={searchValue}
          placeholder="Skriv inn sted, postnummer eller adresse"
        />
      </div>
      {showSearchResults && freeTextSearchResult && searchValue && (
        <ul
          id="map-search-results"
          className="uk-width-1-1 uk-list uk-list-divider uk-margin-remove"
        >
          {freeTextSearchResult.map(
            (searchResult: IFreeTextSearch, index: number) => {
              return (
                <li key={index}>
                  <button
                    className="uk-button-text  uk-button search-result uk-display-block"
                    onClick={() => handleOnClickSearchResult(searchResult)}
                  >
                    {searchResult.Text}
                  </button>
                </li>
              );
            }
          )}
        </ul>
      )}
    </div>
  );
};

export default MapFreeTextSearch;
