import React, { useCallback, useEffect, useState } from "react";
import {
  InfrastructureType,
  infrastructureTypeManualRegistrationRules,
  InfrastructureTypeManualRegistrationRules,
  getEnumKeyByValue,
  geometryTypes,
} from "./IProduct";
import MapImport from "../common/map/mapImport.view";

type RegisterInMapProps = {
  handleOnCreatedDrawing: Function;
  type: InfrastructureType;
  showDrawButtons: boolean;
  numberOfRegisteredProducts: number;
  coords: GeolocationCoordinates | undefined;
};

export const RegisterInMapView = (props: RegisterInMapProps) => {
  const [infrastructureTypeRules] = useState<
    InfrastructureTypeManualRegistrationRules | undefined
  >(
    infrastructureTypeManualRegistrationRules.find(
      (i) => getEnumKeyByValue(InfrastructureType, i.type) === props.type
    )
  );
  const hasReachedMaxNumberOfProductsToRegister =
    props.numberOfRegisteredProducts >=
    (infrastructureTypeRules?.maxRegistrationAmount || 25);
  const legalDrawObjectIsPoint =
    infrastructureTypeRules?.legalGeometryType === geometryTypes.Point;

  const [drawOptions, setDrawOptions] = useState({});

  const getDrawOptions = useCallback(() => {
    !props.showDrawButtons || hasReachedMaxNumberOfProductsToRegister
      ? setDrawOptions({
          circlemarker: false,
          circle: false,
          rectangle: false,
          polygon: false,
          polyline: false,
          marker: false,
        })
      : legalDrawObjectIsPoint
      ? setDrawOptions({
          circlemarker: false,
          circle: false,
          rectangle: false,
          polygon: false,
          polyline: false,
          marker: true,
        })
      : setDrawOptions({
          circlemarker: false,
          circle: false,
          rectangle: false,
          polygon: false,
          marker: false,
        });
  }, [
    hasReachedMaxNumberOfProductsToRegister,
    legalDrawObjectIsPoint,
    props.showDrawButtons,
  ]);

  useEffect(() => {
    getDrawOptions();
  }, [getDrawOptions]);

  return (
    <div className="uk-section uk-padding-remove-bottom">
      <div className="uk-container">
        <h2 className="uk-text-center">Plasser infrastruktur i kartet</h2>
        <div className="uk-padding-left uk-padding-right">
          <p className="uk-margin-medium-bottom">
            Søk opp område hvor du skal plassere elementet og trykk på knappen
            for å legge det til i kartet. Merk: Det er viktig at du er nøyaktig
            når du velger plassering i kartet.
          </p>
          {props.numberOfRegisteredProducts > 0 && (
            <div className="uk-text-right">
              <div>
                <a uk-scroll="true" href="#products">
                  Hopp til elementer
                </a>
              </div>
            </div>
          )}
        </div>
        <MapImport
          drawObjectType={
            infrastructureTypeRules?.type
              ? infrastructureTypeRules?.type
                  .replace(/[åÅæÆ]/g, "a")
                  .replace(/[øØ]/g, "o")
                  .replace(/[^a-zA-Z0-9]/g, "_")
                  .toLowerCase()
              : undefined
          }
          lat={props.coords?.latitude ?? 59.911491}
          lng={props.coords?.longitude ?? 10.757933}
          zoom={props.coords ? 15 : 4}
          onCreatedDraw={(e: any) => props.handleOnCreatedDrawing(e)}
          drawOptions={drawOptions}
        />
      </div>
    </div>
  );
};
