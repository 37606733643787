import {
  fetchManualConstructionsByOrgNrBegin,
  fetchManualConstructionsByOrgNrFailure,
  fetchManualConstructionsByOrgNrSuccess,
  fetchOrgDataByGeoBegin,
  fetchOrgDataByGeoFailure,
  fetchOrgDataByGeoSuccess,
  fetchOrgDataByOrgNrBegin,
  fetchOrgDataByOrgNrFailure,
  fetchOrgDataByOrgNrSuccess
} from './export.actions'
import { saveAs } from 'file-saver';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieve all infrastructure or construction for the given organisation
 * @param isInfrastructure boolean. If true query towards Products endpoint, else towards Construction endpoint
 * @param access_token bearer access token
 * @param organisationId database id of organisation
 */
export function getDataByOrganisationNumber(isInfrastructure: boolean, access_token: string, organisationId: string) {
  return (dispatch: any) => {
    dispatch(fetchOrgDataByOrgNrBegin());

    const endpoint = isInfrastructure ? 'Products' : 'Construction';
    return fetch(baseUrl.concat('/api/' + endpoint + '/export/' + organisationId), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
      .then(async response => {
        if (!response.ok) throw new Error(await response.text());
        else return response.blob()
      })
      .then((res: Blob) => {
        var objectURL = URL.createObjectURL(res);
        saveAs(objectURL, 'Ekomportal' + (isInfrastructure ? 'Infrastruktur' : 'Arbeid') + 'Data_:' + organisationId + ".csv");
        setTimeout(function () {
          URL.revokeObjectURL(objectURL);
        }, 1000)
        dispatch(fetchOrgDataByOrgNrSuccess(res, NotificationMessages.FILEEXPORT_SUCCESS.FETCH))
        return res;
      })
      .catch((error: Error) => dispatch(fetchOrgDataByOrgNrFailure(NotificationMessages.FILEEXPORT_FAILED.FETCH, error)))
  }
}

/**
 * Retrieve all manually registered infrastructure or construction for the given organisation
 * @param access_token bearer access token
 * @param organisationId database id of organisation
 */
 export function getManualConstructionsByOrganisationNumber(access_token: string, organisationId: string) {
  return (dispatch: any) => {
    dispatch(fetchManualConstructionsByOrgNrBegin());

    return fetch(baseUrl.concat('/api/Construction/export/manual/' + organisationId), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
    .then(async response => {
      if(!response.ok) throw new Error(await response.text());
      return response.json();
    })
    .then(json => {
      dispatch(fetchManualConstructionsByOrgNrSuccess(json))
      return json;
    })
    .catch((error: Error) => dispatch(fetchManualConstructionsByOrgNrFailure(NotificationMessages.FILEEXPORT_FAILED.FETCH, error)))
  }
}

/**
 * Fetches csv file with infrastructure data for the marked area
 * @param access_token access token from identity provider
 * @param geo geodata for marked area
 * @param typesToSearchFor: InfrastructureType[] which shall be queried
 */
export function getInfrastructureByGeometry(access_token: string, geo: any, typesToSearchFor?: string[]) {
  return (dispatch: any) => {
    dispatch(fetchOrgDataByGeoBegin());
    return fetch(baseUrl.concat('/api/Products/export/bygeometry'), {
      method: 'post',
      body: JSON.stringify({"geometry": geo, "typeFilter": typesToSearchFor || []}),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    })
      .then(async response => {
        if (!response.ok) throw new Error(await response.text());
        else return response.blob()
      })
      .then((res: Blob) => {
        var objectURL = URL.createObjectURL(res);
        saveAs(objectURL, 'EkomportalSøkeresultat_' + new Date(Date.now()).toISOString() + '.csv');
        setTimeout(function () {
          URL.revokeObjectURL(objectURL);
        }, 1000)
        dispatch(fetchOrgDataByGeoSuccess(res, NotificationMessages.FILEEXPORT_SUCCESS.FETCH))
        return res;
      })
      .catch((error: Error) => dispatch(fetchOrgDataByGeoFailure(NotificationMessages.FILEEXPORT_FAILED.FETCH, error)))
  }
}
/**
 * Fetches csv file with construction data for the marked area
 * @param access_token access token from identity provider
 * @param geo geodata for marked area
 */
export function getConstructionsByGeometry(access_token: string, geo: any) {
  return (dispatch: any) => {
    dispatch(fetchOrgDataByGeoBegin());

    return fetch(baseUrl.concat('/api/construction/export/query'), {
      method: 'post',
      body: JSON.stringify(geo),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    })
      .then(async response => {
        if (!response.ok) throw new Error(await response.text());
        else return response.blob()
      })
      .then((res: Blob) => {
        var objectURL = URL.createObjectURL(res);
        saveAs(objectURL, 'EkomportalSøkeresultat_' + new Date(Date.now()).toISOString() + '.csv');
        setTimeout(function () {
          URL.revokeObjectURL(objectURL);
        }, 1000)
        dispatch(fetchOrgDataByGeoSuccess(res, NotificationMessages.FILEEXPORT_SUCCESS.FETCH))
        return res;
      })
      .catch((error: Error) => dispatch(fetchOrgDataByGeoFailure(NotificationMessages.FILEEXPORT_FAILED.FETCH, error)))
  }
}