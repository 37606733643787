import { LatLngExpression, Map } from "leaflet";
import React, { useEffect, useState } from "react";
import {
  MapContainer as LeafletMap,
  TileLayer,
  FeatureGroup,
  LayersControl,
  ZoomControl,
  useMap,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import {
  configureLeafletDrawLanguage,
  configureLeafletZoomLanguage,
} from "../utilities/configureLeafletElementsLanguage";
import MapFreeTextSearch from "./containers/mapFreeTextSearch";
import { IFreeTextSearch } from "./models/IFreeTextSearch";
import { setLeafletPolyLineOnTouchToFalse } from "../utilities/setLeafletPolylineTouchToFalse";
import { MyPositionControl } from "./containers/MyPositionControl";

const apiKey =
  process.env.REACT_APP_MAP_BACKGROUND_API_KEY ||
  "d1b7f537-552c-49a1-a2b5-90f4169a5b1c";
const { BaseLayer } = LayersControl;

interface MapImportProps {
  drawObjectType?: string;
  lat: number;
  lng: number;
  zoom: number;
  onCreatedDraw: Function;
  drawOptions: object;
}

export const MapImport = (props: MapImportProps) => {
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    setLeafletPolyLineOnTouchToFalse();
    configureLeafletDrawLanguage();
  }, []);

  /**
   * Retrieve x, y coordinates from provided searchResult, which will be sat as this.state.center map center
   * Handle multiple queries on same searchResult by emptying center (setting it to undefined), before setting
   * state value to searchResult coordinates
   * @param selectedSearchResult searchResult: IFreeTextsearch
   */
  const handleOnClickSearchResult = (selectedSearchResult: IFreeTextSearch) => {
    const newLatLngCenter: LatLngExpression = [
      selectedSearchResult.PayLoad.Posisjon.Y,
      selectedSearchResult.PayLoad.Posisjon.X,
    ];

    map?.flyTo(newLatLngCenter, map ? map.getMaxZoom() - 1 : props.zoom);
  };

  const onCreated = (e: any) => {
    props.onCreatedDraw(e);
  };

  return (
    <div
      id="manual-import-container"
      className={props.drawObjectType && "import-type-" + props.drawObjectType}
    >
      <div className="uk-padding uk-padding-remove-top uk-padding-small-bottom uk-width-1-1 uk-width-large@m">
        <MapFreeTextSearch
          onClickSearchResult={(selectedSearchResult: IFreeTextSearch) =>
            handleOnClickSearchResult(selectedSearchResult)
          }
        />
      </div>
      <div className="uk-padding-left@l uk-padding-right@l">
        <LeafletMap
          center={[props.lat, props.lng]}
          zoom={props.zoom}
          touchZoom={true}
          zoomControl={false}
          tap={false}
          ref={setMap}
        >
          <ZoomControl position="bottomright" />
          <LayersControl position="topright">
            <BaseLayer checked name="Vanlig">
              <TileLayer
                url={
                  "https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png?APITOKEN=" +
                  apiKey
                }
                attribution='&copy; <a href="http://www.norkart.no">Norkart AS</a>'
              />
            </BaseLayer>
          </LayersControl>
          <FeatureGroup>
            <EditControl
              position="topleft"
              onCreated={onCreated}
              draw={props.drawOptions}
              edit={{
                edit: false,
                remove: false,
              }}
            />
          </FeatureGroup>
          <MyPositionControl lat={props.lat} lng={props.lng} />
          <SetLang />
          <InvalidateSize />
        </LeafletMap>
      </div>
    </div>
  );
};
export default MapImport;

const InvalidateSize = () => {
  const mapRef = useMap();

  useEffect(() => {
    mapRef.invalidateSize();
  });

  return null;
};
const SetLang = () => {
  useEffect(() => {
    configureLeafletZoomLanguage();
  });

  return null;
};
