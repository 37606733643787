import {
    FETCH_MAPOBJECTS_BEGIN,
    FETCH_MAPOBJECTS_FAILURE,
    FETCH_MAPOBJECTS_SUCCESS,
    REMOVE_MAPOBJECTS,
    FETCH_POSTALLIMITS_BEGIN,
    FETCH_POSTALLIMITS_FAILURE,
    FETCH_POSTALLIMITS_SUCCESS,
    FREETEXT_SEARCH_BEGIN,
    FREETEXT_SEARCH_FAILURE,
    FREETEXT_SEARCH_SUCCESS,
    CLEAR_FETCH_MAPOBJECTS_ERROR
} from './mapSearch.actions';
import { IProductOwner } from './models/IProductOwner'
import { GeoJsonObject } from 'geojson';
import { IFreeTextSearch } from './models/IFreeTextSearch';
import { QueryTypes } from './models/QueryTypes';

export type mapDataState = {
    owners?: IProductOwner[];
    getProductOwnerDataLoading: boolean;
    getProductOwnerDataError?: string;

    postalLimits?: GeoJsonObject;
    getPostalLimitsLoading: boolean;
    postalLimitsError?: string;

    freeTextSearchResult?: IFreeTextSearch[];
    freeTextSearchLoading: boolean;
    freeTextSearchError?: string;

    userSearch?: userSearch;
}

type userSearch = {
    area: any;
    type: QueryTypes
}

export default function mapDataReducer(
    state: mapDataState = {
        getProductOwnerDataLoading: false,
        getPostalLimitsLoading: false,
        freeTextSearchLoading: false
    },
    action: any
) {
    switch (action.type) {
        case FETCH_MAPOBJECTS_BEGIN:
            return {
                ...state,
                getProductOwnerDataLoading: true,
                getProductOwnerDataError: undefined
            };
        case FETCH_MAPOBJECTS_SUCCESS:
            return {
                ...state,
                getProductOwnerDataLoading: false,
                owners: action.payload.owners,
                userSearch: { area: action.payload.userSearchArea, type: action.payload.userSearchType }
            };
        case FETCH_MAPOBJECTS_FAILURE:
            return {
                ...state,
                getProductOwnerDataLoading: false,
                getProductOwnerDataError: action.payload,
                owners: undefined
            };
        case REMOVE_MAPOBJECTS:
            return {
                ...state,
                getProductOwnerDataLoading: false,
                owners: undefined,
                userSearch: undefined
            };
        case CLEAR_FETCH_MAPOBJECTS_ERROR:
            return {
                ...state,
                getProductOwnerDataError: undefined
            }
        case FETCH_POSTALLIMITS_BEGIN:
            return {
                ...state,
                getPostalLimitsLoading: true,
                postalLimitsError: undefined
            };
        case FETCH_POSTALLIMITS_SUCCESS:
            return {
                ...state,
                getPostalLimitsLoading: false,
                postalLimits: action.payload
            };
        case FETCH_POSTALLIMITS_FAILURE:
            return {
                ...state,
                getPostalLimitsLoading: false,
                postalLimitsError: action.payload
            };
        case FREETEXT_SEARCH_BEGIN:
            return {
                ...state,
                freeTextSearchLoading: true,
                freeTextSearchError: undefined
            };
        case FREETEXT_SEARCH_SUCCESS:
            return {
                ...state,
                freeTextSearchLoading: false,
                freeTextSearchResult: action.payload
            };
        case FREETEXT_SEARCH_FAILURE:
            return {
                ...state,
                freeTextSearchLoading: false,
                freeTextSearchError: action.payload
            };
        default:
            return state;
    }
}