import { IProduct } from "../import/IProduct";

export const FETCH_ORG_DATA_BY_ORGNR_BEGIN = 'FETCH_ORG_DATA_BY_ORGNR_BEGIN';
export const FETCH_ORG_DATA_BY_ORGNR_SUCCESS = 'FETCH_ORG_DATA_BY_ORGNR_SUCCESS';
export const FETCH_ORG_DATA_BY_ORGNR_FAILURE = 'FETCH_ORG_DATA_BY_ORGNR_FAILURE';

export const FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_BEGIN = 'FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_BEGIN';
export const FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESS = 'FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESS';
export const FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_FAILURE = 'FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_FAILURE';
export const RESET_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESSFULL = 'RESET_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESSFULL';

export const FETCH_ORG_DATA_BY_GEO_BEGIN = 'FETCH_ORG_DATA_BY_GEO_BEGIN';
export const FETCH_ORG_DATA_BY_GEO_SUCCESS = 'FETCH_ORG_DATA_BY_GEO_SUCCESS';
export const FETCH_ORG_DATA_BY_GEO_FAILURE = 'FETCH_ORG_DATA_BY_GEO_FAILURE';

export const fetchOrgDataByOrgNrBegin = () => ({
  type: FETCH_ORG_DATA_BY_ORGNR_BEGIN
});
export const fetchOrgDataByOrgNrSuccess = (orgData: Blob, message: string) => ({
  type: FETCH_ORG_DATA_BY_ORGNR_SUCCESS,
  payload: { orgData, message: message }
});
export const fetchOrgDataByOrgNrFailure = (error: string, exception: Error) => ({
  type: FETCH_ORG_DATA_BY_ORGNR_FAILURE,
  payload: { message: error, detail: exception.message }
});


export const fetchManualConstructionsByOrgNrBegin = () => ({
  type: FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_BEGIN
});
export const fetchManualConstructionsByOrgNrSuccess = (products: IProduct[]) => ({
  type: FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESS,
  payload: { products }
});
export const fetchManualConstructionsByOrgNrFailure = (error: string, exception: Error) => ({
  type: FETCH_MANUAL_CONSTRUCTIONS_BY_ORGNR_FAILURE,
  payload: { message: error, detail: exception.message }
});
export const resetManualConstructionsByOrgNrSuccessful = () => ({
  type: RESET_MANUAL_CONSTRUCTIONS_BY_ORGNR_SUCCESSFULL
})


export const fetchOrgDataByGeoBegin = () => ({
  type: FETCH_ORG_DATA_BY_GEO_BEGIN
});
export const fetchOrgDataByGeoSuccess = (orgData: Blob, message: string) => ({
  type: FETCH_ORG_DATA_BY_GEO_SUCCESS,
  payload: { orgData, message: message }
});
export const fetchOrgDataByGeoFailure = (error: string, exception: Error) => ({
  type: FETCH_ORG_DATA_BY_GEO_FAILURE,
  payload: { message: error, detail: exception.message }
});
