import {
    FETCH_CONTENTDATA_BEGIN,
    FETCH_CONTENTDATA_FAILURE,
    FETCH_CONTENTDATA_SUCCESS
} from './content.actions';
import { IContent } from './IContent';

export type ContentState = {}

export default function contentReducer(
    state: ContentState = {},
    action: any
)
{
    switch (action.type) {
        case FETCH_CONTENTDATA_BEGIN:
            switch (action.contentId) {
                default:
                    return state;
            }
        case FETCH_CONTENTDATA_SUCCESS:
            switch (action.payload as IContent && action.payload.contentId) {
                default:
                    return state;
            }
        case FETCH_CONTENTDATA_FAILURE:
            switch (action.contentId) {
                default:
                    return state;
            }
        default:
            return state;
    }
}