import {
    postUpdateManualConstructionBegin,
    postUpdateManualConstructionFailure,
    postUpdateManualConstructionSuccess
} from './update.actions';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';
import { IProduct } from '../import/IProduct';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Post manual update construction object
 * @param access_token string bearer access token
 * @param construction A single construction represented by an IProduct
 */
 export function updateManualConstruction(access_token: string, construction: IProduct, organisationNumber: string) {
    return (dispatch: any) => {
      dispatch(postUpdateManualConstructionBegin());
  
      return fetch(baseUrl.concat('/api/Construction/update/manual?ownerId=' + organisationNumber), {
        method: 'post',
        body: JSON.stringify(construction),
        headers: new Headers({
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        })
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
      })
      .then(() => dispatch(postUpdateManualConstructionSuccess()))
      .catch((error: Error) => dispatch(postUpdateManualConstructionFailure(NotificationMessages.POST_PRODUCT_FAILURE.POST, error)));
    }
  }