import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { State } from "redux/rootReducer";
import {
  cancelImportJob,
  getAllImportJobs,
  postImportJob,
} from "requests/Import";
import { IImportJobRequest } from "types/IImportJob";

// const useGetImportJobById = (importId: string) => {
//   const access_token = useSelector(
//     (state: State) => (state.auth.user && state.auth.user.access_token) || ""
//   );
//   return useQuery({
//     queryKey: ["getImportJobById", access_token],
//     queryFn: () => {},
//     enabled: !!access_token,
//   });
// };

const useGetAllImportJobs = (orgNumber: string, isInfrastructure: boolean) => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );

  return useQuery({
    queryKey: ["getAllImportJobs", access_token],
    queryFn: () =>
      getAllImportJobs(
        organisation?.organisationNumber || "",
        isInfrastructure,
        access_token
      ),
    enabled: !!organisation,
    refetchInterval: 15000,
  });
};

function usePostImportJob() {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useMutation({
    mutationKey: ["postimportjob", access_token],
    mutationFn: async (importJobRequest: IImportJobRequest) =>
      postImportJob(access_token, importJobRequest), // Modify the mutationFn to return a Promise<unknown>
    onSuccess: () => {}, // Modify onSuccess to handle the successful upload
    onError: () => {}, // Modify onError to handle the error
  });
}

function useCancelImportJob() {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useMutation({
    mutationKey: ["cancelimportjob", access_token],
    mutationFn: async ({ id, orgNr }: { id: string; orgNr: string }) =>
      cancelImportJob(access_token, id, orgNr), // Modify the mutationFn to return a Promise<unknown>
    onSuccess: async (data) => {
      return data;
    },
    onError: async (error) => {
      return error;
    },
  });
}

export { useGetAllImportJobs, usePostImportJob, useCancelImportJob };
