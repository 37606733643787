import {
  postDeleteManualConstructionBegin,
  postDeleteManualConstructionFailure,
  postDeleteManualConstructionSuccess,
  deleteElementsByOwnerIdBegin,
  deleteElementsByOwnerIdSuccess,
  deleteElementsByOwnerIdFailure
} from './delete.actions';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Post manual delete construction object
 * @param access_token string bearer access token
 */
 export function deleteManualConstruction(access_token: string, deleteConstructionId: string, organisationNumber: string) {
    return (dispatch: any) => {
      dispatch(postDeleteManualConstructionBegin());
  
      return fetch(baseUrl.concat('/api/Construction/delete/manual?ownerId=' + organisationNumber + '&productId=' + deleteConstructionId), {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        })
      })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
      })
      .then(() => dispatch(postDeleteManualConstructionSuccess()))
      .catch((error: Error) => dispatch(postDeleteManualConstructionFailure(NotificationMessages.POST_PRODUCT_FAILURE.POST, error)));
    }
 }
  
 /**
  * Deletes all elements for for the given organisation and elementtype
  * @param access_token 
  * @param organisationNumber 
  * @param isInfrastructure
  */
export function deleteElementsByOwnerId(access_token: string, organisationNumber: string, isInfrastructure: boolean) { 
  return (dispatch: any) => {
      dispatch(deleteElementsByOwnerIdBegin());
  
      return fetch(baseUrl.concat('/api/' + (isInfrastructure ? 'Products' : 'Construction') + '/delete/' + organisationNumber), {
        method: 'delete',
        headers: new Headers({
          Authorization: 'Bearer ' + access_token
        })
      })
        .then(async response => {
          let responseText = await response.text();
        if (!response.ok) {
          throw new Error(responseText)
        }
        else {
          let numberDeleted = parseInt(responseText);
          let elementType = isInfrastructure ? "infrastrukturobjekter" : "bygge- og anleggsarbeid";
          dispatch(deleteElementsByOwnerIdSuccess(numberDeleted, (numberDeleted > 0 ? "Sletting fullført. " : "Fant ingen " + elementType +" å slette") , numberDeleted > 0 ? "Alle (" + numberDeleted.toLocaleString() + ") " + elementType +" er slettet" : ""))
          }
      })
      .catch((error: Error) => dispatch(deleteElementsByOwnerIdFailure('Sletting feilet.', error)));
    }
}