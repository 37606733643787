import React from 'react';

export function Business() {
  return (
    <span className="uk-icon">
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2V39.0001H25.9412V2H2Z" stroke="#3028AA" strokeWidth="3" strokeLinejoin="round"/>
        <path d="M39.0003 39.0002H25.9414V10.7061H39.0003V39.0002Z" stroke="#3028AA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="6.35303" y="7.44116" width="6.52943" height="4.35295" fill="#3028AA"/>
        <rect x="6.35303" y="13.9701" width="6.52943" height="4.35295" fill="#3028AA"/>
        <rect x="6.35303" y="20.5" width="6.52943" height="4.35295" fill="#3028AA"/>
        <rect x="15.0586" y="7.44116" width="6.52943" height="4.35295" fill="#3028AA"/>
        <rect x="15.0586" y="13.9701" width="6.52943" height="4.35295" fill="#3028AA"/>
        <rect x="15.0586" y="20.5" width="6.52943" height="4.35295" fill="#3028AA"/>
        </svg>
    </span>
  )
}