import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../redux/rootReducer";
import { Navigate } from "react-router";
import { unAuthorizedRole } from "../../common/user/user.action";
import {
  IOrganisationContactInformation,
  IOrganisation,
} from "../../common/organisation/IOrganisation";
import { handleHasRole } from "../../common/utilities/roleHandler";
import {
  hasRole,
  UIKit,
  ValidationWarning,
  ModalView,
} from "@egdeconsulting/ekom_lib";
import {
  getOrganisationByOrgNr,
  updateOrganisation,
  getOrganisationAPIKey,
  postGenerateOrganisationAPIKey,
} from "../../common/organisation/organisation.service";
import {
  validateEmail,
  validatePhonenumber,
  validateUrl,
  validateZipcode,
} from "../../common/utilities/validator";

const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";
const Profile = () => {
  const [contactInfo, setContactInfo] =
    useState<IOrganisationContactInformation>();
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [showNewAPIKey, setShowNewAPIKey] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [showGenerateAPIKeyModal, setShowGenerateAPIKeyModal] = useState(false);

  const {
    access_token,
    activeRole,
    fetchOrganisationLoading,
    organisation,
    updateOrganisationLoading,
    fetchOrganisationError,
    fetchOrganisationAPIKeyLoading,
    organisationAPIKey,
    generateOrganisationAPIKeyLoading,
    generateOrganisationAPIKeyError,
  } = useSelector((state: State) => ({
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    activeRole: state.user.activeRole,
    fetchOrganisationLoading: state.organisation.fetchOrganisationLoading,
    organisation: state.organisation.organisation,
    updateOrganisationLoading: state.organisation.updateOrganisationLoading,
    fetchOrganisationError: state.organisation.fetchOrganisationError,
    fetchOrganisationAPIKeyLoading:
      state.organisation.fetchOrganisationAPIKeyLoading,
    organisationAPIKey: state.organisation.organisationAPIKey,
    generateOrganisationAPIKeyLoading:
      state.organisation.generateOrganisationAPIKeyLoading,
    generateOrganisationAPIKeyError:
      state.organisation.generateOrganisationAPIKeyError,
  }));

  const dispatch = useDispatch();

  // Håndterer andre livssyklusmetoder som componentDidMount i bruk av hooks
  useEffect(() => {
    handleHasRole();
  }, []);

  const handleInputChange = (
    event: any,
    contanctInfo?: IOrganisationContactInformation
  ) => {
    const fieldName = event.target.name;
    let tmpContactInfo = contanctInfo || contactInfo || {};
    if (fieldName === "streetAddress")
      tmpContactInfo.streetAddress = event.target.value;
    if (fieldName === "zipCode") tmpContactInfo.zipCode = event.target.value;
    if (fieldName === "postalPlace")
      tmpContactInfo.postalPlace = event.target.value;
    if (fieldName === "email") tmpContactInfo.email = event.target.value;
    if (fieldName === "phoneNumber")
      tmpContactInfo.phoneNumber = event.target.value;
    if (fieldName === "websiteUrl")
      tmpContactInfo.websiteUrl = event.target.value;
    setContactInfo(tmpContactInfo);
  };

  const handleSubmit = (organisationToUpdate: IOrganisation | undefined) => {
    if (contactInfo && organisationToUpdate) {
      organisationToUpdate.contactInformation = contactInfo;
      dispatch<any>(updateOrganisation(access_token, organisationToUpdate));
    }
  };
  const handleShowAPIKey = () => {
    setShowAPIKey(true);
    setShowNewAPIKey(false);
    setShowButtons(false);
    if (!fetchOrganisationAPIKeyLoading && !organisationAPIKey) {
      activeRole &&
        dispatch<any>(getOrganisationAPIKey(access_token, activeRole.id));
    }
  };

  const handleOnClickGenerateAPIKey = () => {
    setShowGenerateAPIKeyModal(true);
  };

  const handleGenerateAPIKey = () => {
    setShowAPIKey(true);
    setShowGenerateAPIKeyModal(false);
    setShowNewAPIKey(false);
    setShowButtons(false);

    if (
      !generateOrganisationAPIKeyLoading &&
      !generateOrganisationAPIKeyError
    ) {
      activeRole &&
        dispatch<any>(
          postGenerateOrganisationAPIKey(access_token, activeRole.id)
        );
    }
  };

  const streetAddress =
    contactInfo?.streetAddress ??
    organisation?.contactInformation?.streetAddress ??
    "";
  const zipCode =
    contactInfo?.zipCode ?? organisation?.contactInformation?.zipCode ?? "";
  const postalPlace =
    contactInfo?.postalPlace ??
    organisation?.contactInformation?.postalPlace ??
    "";
  const email =
    contactInfo?.email ?? organisation?.contactInformation?.email ?? "";
  const phoneNumber =
    contactInfo?.phoneNumber ??
    organisation?.contactInformation?.phoneNumber ??
    "";
  const websiteUrl =
    contactInfo?.websiteUrl ??
    organisation?.contactInformation?.websiteUrl ??
    "";

  const formDataLegal =
    !!streetAddress &&
    !!postalPlace &&
    validateZipcode(zipCode) &&
    validateEmail(email) &&
    validatePhonenumber(phoneNumber) &&
    validateUrl(websiteUrl);

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      if (
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, false)
        );
      }
      return (
        <UIKit.Section>
          <UIKit.Container>
            <h1>Kontaktinformasjon for {activeRole.name}</h1>
            <label className="uk-h4" htmlFor="streetAddress">
              Gateadresse
            </label>
            <input
              className="uk-display-block uk-input uk-width-medium"
              type="text"
              id="streetAddress"
              name="streetAddress"
              placeholder="Gateadresse"
              value={streetAddress}
              onChange={(e) =>
                handleInputChange(e, organisation?.contactInformation)
              }
            />
            <div className="uk-margin-small-top">
              <label className="uk-h4" htmlFor="zipCode">
                Postnummer
              </label>
              <input
                className={`uk-display-block uk-input uk-width-medium ${
                  zipCode && !validateZipcode(zipCode) && "uk-form-danger"
                }`}
                type="number"
                id="zipCode"
                name="zipCode"
                placeholder="0001"
                max={9999}
                min={1}
                value={zipCode}
                onChange={(e) =>
                  handleInputChange(e, organisation?.contactInformation)
                }
              />
              {zipCode && !validateZipcode(zipCode) && (
                <ValidationWarning text="Postnummer må være 4 tall" />
              )}
            </div>
            <div className="uk-margin-small-top">
              <label className="uk-h4" htmlFor="postalPlace">
                Poststed
              </label>
              <input
                className="uk-display-block uk-input uk-width-medium"
                type="text"
                id="postalPlace"
                name="postalPlace"
                placeholder="Poststed"
                value={postalPlace}
                onChange={(e) =>
                  handleInputChange(e, organisation?.contactInformation)
                }
              />
            </div>
            <div className="uk-margin-small-top">
              <label className="uk-h4" htmlFor="email">
                E-post
              </label>
              <input
                className={`uk-display-block uk-input uk-width-medium ${
                  email && !validateEmail(email) && "uk-form-danger"
                }`}
                type="email"
                id="email"
                name="email"
                placeholder="E-post"
                value={email}
                onChange={(e) =>
                  handleInputChange(e, organisation?.contactInformation)
                }
              />
              {email && !validateEmail(email) && (
                <ValidationWarning text="E-postadressen er ikke gyldig" />
              )}
            </div>
            <div className="uk-margin-small-top">
              <label className="uk-h4" htmlFor="phoneNumber">
                Telefonnummer
              </label>
              <input
                className={`uk-display-block uk-input uk-width-medium ${
                  phoneNumber &&
                  !validatePhonenumber(phoneNumber) &&
                  "uk-form-danger"
                }`}
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="00000000"
                value={phoneNumber}
                onChange={(e) =>
                  handleInputChange(e, organisation?.contactInformation)
                }
              />
              {phoneNumber && !validatePhonenumber(phoneNumber) && (
                <ValidationWarning text="Telefonnummer må være 8 tall" />
              )}
            </div>
            <div className="uk-margin-small-top">
              <label className="uk-h4" htmlFor="websiteUrl">
                Webside
              </label>
              <input
                className={`uk-display-block uk-input uk-width-medium ${
                  websiteUrl && !validateUrl(websiteUrl) && "uk-form-danger"
                }`}
                type="text"
                id="websiteUrl"
                name="websiteUrl"
                placeholder="Webside"
                value={websiteUrl}
                onChange={(e) =>
                  handleInputChange(e, organisation?.contactInformation)
                }
              />
              {websiteUrl && !validateUrl(websiteUrl) && (
                <ValidationWarning text="Nettsideadressen er ikke gyldig" />
              )}
            </div>
            {updateOrganisationLoading ? (
              <UIKit.Container>
                <UIKit.Spinner>Oppdaterer profil</UIKit.Spinner>
              </UIKit.Container>
            ) : (
              <UIKit.Button
                className="uk-margin-medium"
                color="primary"
                disabled={!contactInfo || !formDataLegal}
                onClick={() => handleSubmit(organisation)}
              >
                Oppdater
              </UIKit.Button>
            )}
            <div>
              {(fetchOrganisationAPIKeyLoading ||
                generateOrganisationAPIKeyLoading) && (
                <UIKit.Spinner>Laster</UIKit.Spinner>
              )}
              {showAPIKey &&
                organisationAPIKey &&
                !fetchOrganisationAPIKeyLoading &&
                !generateOrganisationAPIKeyLoading && (
                  <p>API-nøkkel: {organisationAPIKey}</p>
                )}
              {showNewAPIKey &&
                organisationAPIKey &&
                !fetchOrganisationAPIKeyLoading &&
                !generateOrganisationAPIKeyLoading && (
                  <p>Ny API-nøkkel: {organisationAPIKey}</p>
                )}
            </div>
            {showButtons &&
              !fetchOrganisationAPIKeyLoading &&
              !generateOrganisationAPIKeyLoading && (
                <div>
                  <div className="uk-margin">
                    <UIKit.Button
                      color="primary"
                      disabled={!organisation?.contactInformation}
                      onClick={() => handleShowAPIKey()}
                    >
                      Hent API-nøkkel
                    </UIKit.Button>
                  </div>
                  <div className="uk-margin">
                    <UIKit.Button
                      color="primary"
                      disabled={!organisation?.contactInformation}
                      onClick={() => handleOnClickGenerateAPIKey()}
                    >
                      Lag ny API-nøkkel
                    </UIKit.Button>
                    {!organisation?.contactInformation ? (
                      <p className="uk-text-muted">
                        Gyldig profildata må være registrert før API-nøkkel kan
                        genereres
                      </p>
                    ) : (
                      <p className="uk-text-muted">
                        Merk: Nåværende API-nøkkel vil bli slettet hvis du
                        velger å lage ny API-nøkkel
                      </p>
                    )}
                  </div>
                </div>
              )}
            <ModalView
              show={showGenerateAPIKeyModal}
              size="sm"
              modalTitle="Er du helt sikker på at du vil lage en ny API-nøkkel?"
              onClose={() => setShowGenerateAPIKeyModal(false)}
              onSubmit={() => handleGenerateAPIKey()}
              submitButtonText={"Ja"}
              closeButtonText={"Nei"}
            >
              <div>
                <p className="uk-margin-small-top uk-text-center">
                  Er du helt sikker på at du vil lage en ny API-nøkkel?
                </p>
                <p className="uk-text-center">
                  Dersom du trykker på Ja, vil nåværende API-nøkkel bli
                  erstattet med en ny API-nøkkel.
                </p>
              </div>
            </ModalView>
          </UIKit.Container>
        </UIKit.Section>
      );
    } else if (!fetchOrganisationError)
      return (
        <UIKit.Container>
          <UIKit.Spinner>Henter firmaprofil</UIKit.Spinner>
        </UIKit.Container>
      );
    else return <div></div>;
  }

  dispatch<any>(
    unAuthorizedRole(
      "For å se profildata må du ha rollen: " + importRoleName,
      new Error("")
    )
  );
  return <Navigate to="/" />;
};
export default Profile;
