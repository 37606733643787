import {
  POST_FILE_BEGIN,
  POST_FILE_FAILURE,
  POST_FILE_SUCCESSFULL,
  POST_PRODUCT_BEGIN,
  POST_PRODUCT_FAILURE,
  POST_PRODUCT_SUCCESS,
  RESET_POST_PRODUCT_SUCCESSFULL
} from './import.actions';

export type ImportState = {
  postFileLoading: boolean;
  postFileError?: string;

  postProductLoading: boolean;
  postProductError?: string;
  postProductSuccessfull: boolean;
};

export default function contentReducer(
  state: ImportState = {
    postFileLoading: false,
    postProductLoading: false,
    postProductSuccessfull: false
  },
  action: any
) {
  switch (action.type) {
    case POST_FILE_BEGIN:
      return {
        ...state,
        postFileLoading: true,
        postFileError: undefined
      };
    case POST_FILE_SUCCESSFULL:
      return {
        ...state,
        postFileLoading: false
      };
    case POST_FILE_FAILURE:
      return {
        ...state,
        postFileLoading: false,
        postFileError: action.payload
      };
    case POST_PRODUCT_BEGIN:
      return {
        ...state,
        postProductLoading: true,
        postProductError: undefined,
        postProductSuccessfull: false
      };
    case POST_PRODUCT_SUCCESS:
      return {
        ...state,
        postProductLoading: false,
        postProductSuccessfull: true
      }
    case POST_PRODUCT_FAILURE:
      return {
        ...state,
        postProductLoading: false,
        postProductError: action.payload.message,
        postProductSuccessfull: false
      };
    case RESET_POST_PRODUCT_SUCCESSFULL:
      return {
        ...state,
        postProductSuccessfull: false
      }
    default:
      return state;
  }
}