import React from "react";
import { IProduct } from "./IProduct";
import { ProductEditRowView } from "./productEditRow.view";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { swapCoordinatesOrder } from "../common/utilities/parser";

type ProductListViewProps = {
  products: IProduct[];
  productBeingEditedIndex?: number;
  productBeingEdited?: IProduct;
  handleRemove: Function;
  handleEdit: Function;
  handleInputChange: Function;
  handleSave: Function;
  handleAbortEdit: Function;
};

export const ProductListView = (props: ProductListViewProps) => {
  return (
    <div id="products" className="uk-padding-left@l uk-padding-right@l">
      <ul className="uk-list uk-list-divider uk-margin-remove-bottom">
        <li className="uk-visible@m uk-padding-remove-horizontal">
          <UIKit.Grid className="uk-grid uk-child-width-1-4@m">
            <div className="uk-text-bold uk-text-medium">Lokasjon</div>
            <div className="uk-text-bold uk-text-medium">Navn</div>
            <div className="uk-text-bold uk-text-medium">Status</div>
            <div></div>
          </UIKit.Grid>
        </li>
        {props.products.length === 0 && (
          <li className="uk-text-center uk-text-italic">
            Ingen elementer lagt til
          </li>
        )}
        {props.products.map((product: IProduct, index: number) => {
          if (
            props.products.indexOf(product) === props.productBeingEditedIndex
          ) {
            return (
              <ProductEditRowView
                key={index}
                product={product}
                productBeingEdited={props.productBeingEdited}
                handleInputChange={props.handleInputChange}
                handleAbortEdit={() => props.handleAbortEdit()}
                handleSave={() => props.handleSave()}
              />
            );
          } else {
            const coordinatesInLatLong =
              product.geometry?.coordinates &&
              swapCoordinatesOrder(
                JSON.parse(JSON.stringify(product.geometry?.coordinates))
              );
            return (
              <li key={index} className="uk-padding-remove-horizontal">
                <UIKit.Grid
                  options="margin:uk-margin-small"
                  className="uk-grid uk-child-width-1-4@m"
                >
                  <div className="uk-overflow-hidden uk-text-truncate">
                    <div className="uk-hidden@m uk-text-bold">Lokasjon:</div>
                    {coordinatesInLatLong.map(
                      (coordinate: any, index: number) => {
                        if (!coordinate.length)
                          return (
                            coordinate +
                            (index + 1 !== coordinatesInLatLong.length && ",")
                          );
                        return coordinate + " ";
                      }
                    ) || ""}
                  </div>
                  <div className="uk-overflow-hidden uk-text-truncate">
                    <div className="uk-hidden@m uk-text-bold">Navn:</div>
                    {product.name}
                  </div>
                  <div className="uk-overflow-hidden uk-text-truncate">
                    <div className="uk-hidden@m uk-text-bold">Status:</div>
                    {product.status}
                  </div>
                  <div className="uk-text-right@m uk-margin-remove-bottom">
                    <button
                      className="uk-button-link uk-button"
                      onClick={() => props.handleEdit(index)}
                      disabled={props.productBeingEdited !== undefined}
                    >
                      Endre
                    </button>
                    <button
                      className="uk-button-link uk-button uk-margin-medium-left"
                      onClick={() => props.handleRemove(index)}
                    >
                      <span uk-icon="icon: trash" />
                    </button>
                  </div>
                </UIKit.Grid>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};
