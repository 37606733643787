import {
  fetchLimitsBegin,
  fetchLimitsFailure,
  fetchLimitsSuccess
} from './limits.actions';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';


const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieve limits 
 * @param access_token bearer access token
 */
export function getLimits(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchLimitsBegin());
    return fetch(baseUrl.concat('/api/limits'), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
        else return await response.json()
      })
      .then(limits => {
        dispatch(fetchLimitsSuccess(limits));
        return limits;
      })
      .catch((error: Error) => dispatch(fetchLimitsFailure(NotificationMessages.LIMITS_FAILED.FETCH, error)));
  }
}