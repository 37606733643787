import React, { useCallback, useEffect, useState } from "react";
import { State } from "../../redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import MapImport from "../../common/map/mapImport.view";
import {
  IProduct,
  ConstructionType,
  ConstructionTypeMappedToDesc,
  getEnumKeyByValue,
} from "../IProduct";
import { postProducts } from "../import.service";
import {
  hasRole,
  UIKit,
  ValidationWarning,
  ModalView,
} from "@egdeconsulting/ekom_lib";
import { unAuthorizedRole } from "../../common/user/user.action";
import { Navigate } from "react-router";
import { getOrganisationByOrgNr } from "../../common/organisation/organisation.service";
import { resetPostProductSuccessfull } from "../import.actions";
import { swapCoordinatesOrder } from "../../common/utilities/parser";
import InputMask from "react-input-mask";
import { useGeolocated } from "react-geolocated";

const ConstructionTypesWithDescription: ConstructionTypeMappedToDesc[] = [
  {
    type: ConstructionType.NkomOppføringAvBygning,
    description: "",
  },
  {
    type: ConstructionType.NkomInnredningUtsmykkingsInstallasjonsArbeid,
    description: "",
  },
  {
    type: ConstructionType.NkomMonteringDemonteringPrefabrikert,
    description: "",
  },
  {
    type: ConstructionType.NkomRivingDemonteringOmbyggingIstandsetting,
    description: "",
  },
  {
    type: ConstructionType.NkomSaneringVedlikehold,
    description: "",
  },
  {
    type: ConstructionType.NkomAlminneligAnleggsvirksomhet,
    description: "",
  },
  {
    type: ConstructionType.NkomGravingSprengningGrunnarbeid,
    description: "",
  },
  {
    type: ConstructionType.NkomAnnetArbeid,
    description: "",
  },
];

const userManualsURL = process.env.REACT_APP_USER_MANUALS_URL || "0";
const importRoleName = process.env.REACT_APP_IMPORT_ROLE_NAME || "import";

export const ManualConstruction = () => {
  const dispatch = useDispatch();
  const { coords } = useGeolocated();

  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) ?? ""
  );
  const postProductsLoading = useSelector(
    (state: State) => state.importProduct.postProductLoading
  );
  const activeRole = useSelector((state: State) => state.user.activeRole);

  const fetchOrganisationLoading = useSelector(
    (state: State) => state.organisation.fetchOrganisationLoading
  );
  const organisation = useSelector(
    (state: State) => state.organisation.organisation
  );
  const fetchOrganisationError = useSelector(
    (state: State) => state.organisation.fetchOrganisationError
  );
  const postProductSuccessfull = useSelector(
    (state: State) => state.importProduct.postProductSuccessfull
  );

  const [showMap, setShowMap] = useState(false);
  const [newConstruction, setNewConstruction] = useState<IProduct | undefined>({
    type: undefined,
    geometry: undefined,
  });
  const [startDate, setStartDate] = useState({
    value: "",
    validationWarning: "",
  });
  const [endDate, setEndDate] = useState({
    value: "",
    validationWarning: "",
  });

  useEffect(() => {
    if (postProductSuccessfull) {
      setNewConstruction({
        type: undefined,
        geometry: undefined,
      });
      dispatch(resetPostProductSuccessfull());
    }
  }, [postProductSuccessfull, dispatch]);

  useEffect(() => {
    if (activeRole) {
      const hasRequiredRole = hasRole(importRoleName, activeRole);
      if (
        hasRequiredRole &&
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, true)
        );
      }
    }
  }, [
    activeRole,
    fetchOrganisationLoading,
    organisation,
    fetchOrganisationError,
    access_token,
    dispatch,
  ]);

  const handleDateInputChange = (event: any) => {
    if (event.target.name === "startDate") {
      setStartDate({ value: event.target.value, validationWarning: "" });

      //validateState();
    } else if (event.target.name === "endDate") {
      setTimeout(() => {
        setEndDate({ value: event.target.value, validationWarning: "" });
      });

      // validateState();
    }
  };
  /**
   * Validate date values in state. Set validationwarning messages if validation fails.
   */
  const validateState = useCallback(() => {
    if (dateInputCompleted(startDate.value) && !dateIsValid(startDate.value)) {
      setTimeout(() =>
        setStartDate({
          value: startDate.value,
          validationWarning: "Ugyldig dato",
        })
      );
    } else if (
      dateInputCompleted(endDate.value) &&
      !dateIsValid(endDate.value)
    ) {
      setTimeout(() =>
        setEndDate({ value: endDate.value, validationWarning: "Ugyldig dato" })
      );
    } else if (
      dateInputCompleted(startDate.value) &&
      dateInputCompleted(endDate.value) &&
      new Date(endDate.value.split(".").reverse().join("-")) <=
        new Date(startDate.value.split(".").reverse().join("-"))
    ) {
      setTimeout(() =>
        setEndDate({
          value: endDate.value,
          validationWarning:
            "Startdato kan ikke være større eller lik sluttdato",
        })
      );
    } else if (
      dateInputCompleted(startDate.value) &&
      new Date(startDate.value.split(".").reverse().join("-")) >=
        new Date("2999-12-31")
    ) {
      setTimeout(() =>
        setStartDate({
          value: startDate.value,
          validationWarning: "Datoene kan ikke overstige dette årtusenet",
        })
      );
    } else if (
      dateInputCompleted(endDate.value) &&
      new Date(endDate.value.split(".").reverse().join("-")) >
        new Date("2999-12-31")
    ) {
      setTimeout(() =>
        setEndDate({
          value: endDate.value,
          validationWarning: "Datoene kan ikke overstige dette årtusenet",
        })
      );
    } else {
      setTimeout(() => {
        setStartDate({ value: startDate.value, validationWarning: "" });
        setEndDate({ value: endDate.value, validationWarning: "" });
      });
    }
  }, [startDate.value, endDate.value]);

  useEffect(() => {
    validateState();
  }, [
    startDate.value,
    startDate.validationWarning,
    endDate.value,
    endDate.validationWarning,
    validateState,
  ]);

  /**
   * Checks if day,month and year is completed in the input field
   * @param value input value
   * @return boolean
   */
  const dateInputCompleted = (value: string) => {
    return value.split("_").join("").split(".").join("").length === 8;
  };
  /**
   * Checks if the input string is a valid date or not
   * @param date string from an input field
   * @return boolean
   */
  const dateIsValid = (date: string) => {
    let values = date.replaceAll("_", "").split(".");

    if (values[0] !== "" && values[1] !== "" && values[2].length === 4) {
      const date = new Date(
        parseInt(values[2]),
        +values[1] - 1,
        parseInt(values[0])
      );
      const isValidDate =
        Boolean(+date) && date.getDate() === parseInt(values[0]);
      const isValidMonth =
        Boolean(+date) && date.getMonth() === parseInt(values[1]) - 1;

      return isValidDate && isValidMonth;
    }
    return false;
  };
  const handleInputChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    let tmpNewConstruction = newConstruction || {};
    if (fieldName === "type") tmpNewConstruction.type = value;
    if (fieldName === "name") tmpNewConstruction.name = value;
    if (fieldName === "status") tmpNewConstruction.status = value;
    setTimeout(() => setNewConstruction(tmpNewConstruction));
  };
  const handleOnCreatedDrawing = (e: any) => {
    let tmpNewConstruction = newConstruction || {};
    tmpNewConstruction.geometry = e.layer.toGeoJSON().geometry;
    setTimeout(() => setNewConstruction(tmpNewConstruction));
    setShowMap(false);
  };
  const handleSubmit = (orgNr: string) => {
    if (
      dateInputCompleted(startDate.value) &&
      startDate.validationWarning === "" &&
      dateInputCompleted(endDate.value) &&
      endDate.validationWarning === ""
    ) {
      let tmpNewConstruction = newConstruction || {};

      tmpNewConstruction.startDate = new Date(
        startDate.value.split(".").reverse().join("-")
      );
      tmpNewConstruction.endDate = new Date(
        endDate.value.split(".").reverse().join("-")
      );

      dispatch<any>(
        postProducts(false, access_token, [tmpNewConstruction], orgNr)
      );
    }
  };

  const coordinatesInLatLong =
    newConstruction?.geometry?.coordinates &&
    swapCoordinatesOrder(
      JSON.parse(JSON.stringify(newConstruction.geometry.coordinates))
    );

  if (activeRole) {
    const hasRequiredRole: boolean = hasRole(importRoleName, activeRole);
    if (hasRequiredRole) {
      if (
        !fetchOrganisationLoading &&
        !organisation &&
        !fetchOrganisationError
      ) {
        dispatch<any>(
          getOrganisationByOrgNr(access_token, activeRole.id, true)
        );
      }
      if (postProductsLoading)
        return (
          <UIKit.Section>
            <UIKit.Container>
              <UIKit.Spinner>Laster opp</UIKit.Spinner>
            </UIKit.Container>
          </UIKit.Section>
        );
      return (
        <UIKit.Section>
          <UIKit.Container>
            <h1 className="uk-text-center">
              Registrer bygge- og anleggsarbeider manuelt
            </h1>
            <p>
              Her kan du registrere bygge- og anleggsarbeider manuelt, hvis du
              ikke har en CSV-, GeoJSON-, SOSI-, eller GML-fil å laste opp.
            </p>
            <p>
              <a href={userManualsURL}>Gå til brukermanualer</a>
            </p>
            <p>
              <a href="construction-file-import">
                Gå tilbake til registrering og redigering av bygge- og
                anleggsarbeider
              </a>
            </p>
            <label className="uk-h4">
              Navn på bygge- og anleggsarbeid (frivillig)
            </label>
            <input
              name="name"
              type="text"
              className="uk-display-block uk-input uk-width-large"
              aria-label="Navn på bygge- og anleggsarbeid"
              onChange={(e) => handleInputChange(e)}
            />
            <h2 className="uk-h4">Type bygge- og anleggsarbeid: </h2>
            {Object.values(ConstructionTypesWithDescription).map(
              (product: ConstructionTypeMappedToDesc, index: number) => (
                <div key={index} className="uk-flex uk-flex-top">
                  <div className="uk-display-inline-block uk-margin-small-right">
                    <input
                      id={product.type}
                      required
                      type="radio"
                      className="uk-radio"
                      name="type"
                      onChange={(e) => handleInputChange(e)}
                      value={getEnumKeyByValue(ConstructionType, product.type)}
                    />
                  </div>
                  <div className="uk-display-inline-block">
                    <label className="uk-margin" htmlFor={product.type}>
                      {product.type}
                    </label>
                  </div>
                </div>
              )
            )}
            <div className="uk-margin">
              <label className="uk-h4">Startdato</label>
              <InputMask
                name="startDate"
                placeholder="dd.mm.åååå"
                mask="99.99.9999"
                className={`uk-display-block uk-input uk-width-large ${
                  startDate.validationWarning && "uk-form-danger"
                }`}
                onChange={(e: any) => handleDateInputChange(e)}
              />
              {startDate.validationWarning && (
                <ValidationWarning
                  text={startDate.validationWarning}
                ></ValidationWarning>
              )}
            </div>
            <div className="uk-margin">
              <label className="uk-h4">Sluttdato</label>
              <InputMask
                name="endDate"
                placeholder="dd.mm.åååå"
                mask="99.99.9999"
                className={`uk-display-block uk-input uk-width-large ${
                  endDate.validationWarning && "uk-form-danger"
                }`}
                onChange={(e: any) => handleDateInputChange(e)}
              />
              {endDate.validationWarning && (
                <ValidationWarning
                  text={endDate.validationWarning}
                ></ValidationWarning>
              )}
            </div>
            <div className="uk-margin">
              <UIKit.Button
                className="uk-flex uk-flex-middle uk-flex-center uk-width-medium"
                color="secondary"
                onClick={() => setShowMap(true)}
              >
                {!showMap && newConstruction?.geometry && (
                  <span uk-icon="icon: check; ratio: .8;"></span>
                )}
                <span>Finn lokasjon i kart</span>
              </UIKit.Button>

              {!showMap && newConstruction?.geometry && (
                <div className="uk-margin-small-top">
                  <button className="uk-width-medium uk-button-link uk-button">
                    Vis koordinater
                  </button>
                  <div uk-dropdown="mode: click">
                    <h4>Valgte koordinater:</h4>
                    <ul className="uk-list">
                      {Array.isArray(coordinatesInLatLong[0]) ? (
                        coordinatesInLatLong.map(
                          (coordinates: any, index: number) => (
                            <li key={index}>
                              {coordinates.length > 2 ? (
                                coordinates.map(
                                  (polyCoords: any, innerIndex: number) => (
                                    <UIKit.Grid
                                      gutter="collapse"
                                      key={innerIndex}
                                      options={"margin:uk-margin-small"}
                                      className="uk-margin-remove uk-child-width-1-2"
                                    >
                                      <div>{polyCoords[0]}</div>
                                      <div>{polyCoords[1]}</div>
                                    </UIKit.Grid>
                                  )
                                )
                              ) : (
                                <UIKit.Grid
                                  gutter="collapse"
                                  key={index}
                                  options={"margin:uk-margin-small"}
                                  className="uk-margin-remove uk-child-width-1-2"
                                >
                                  <div>{coordinates[0]}</div>
                                  <div>{coordinates[1]}</div>
                                </UIKit.Grid>
                              )}
                            </li>
                          )
                        )
                      ) : (
                        <li>
                          <UIKit.Grid
                            gutter="collapse"
                            options={"margin:uk-margin-small"}
                            className="uk-margin-remove uk-child-width-1-2"
                          >
                            <div>{coordinatesInLatLong[0]}</div>
                            <div>{coordinatesInLatLong[1]}</div>
                          </UIKit.Grid>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <UIKit.Button
              color="primary"
              className="uk-width-medium"
              disabled={
                !!!newConstruction?.geometry ||
                !newConstruction?.type ||
                (dateInputCompleted(startDate.value) &&
                  startDate.validationWarning !== "") ||
                (dateInputCompleted(endDate.value) &&
                  endDate.validationWarning !== "") ||
                !dateInputCompleted(startDate.value) ||
                !dateInputCompleted(endDate.value) ||
                !organisation?.contactInformation
              }
              onClick={() => activeRole && handleSubmit(activeRole.id)}
            >
              Registrer
            </UIKit.Button>
            <ModalView
              size="xl"
              show={showMap}
              modalTitle="Marker bygge- og anleggsarbeid i kart"
              onClose={() => setShowMap(false)}
            >
              <MapImport
                lat={coords?.latitude ?? 59.911491}
                lng={coords?.longitude ?? 10.757933}
                zoom={coords ? 15 : 4}
                onCreatedDraw={handleOnCreatedDrawing}
                drawOptions={{
                  circlemarker: false,
                  circle: false,
                  rectangle: false,
                  polygon: { drawError: { message: "" } },
                }}
              />
            </ModalView>
          </UIKit.Container>
        </UIKit.Section>
      );
    }
  }
  dispatch(
    unAuthorizedRole(
      "For å importere må du ha rollen: " + importRoleName,
      new Error("")
    )
  );
  return <Navigate to="/" />;
};

export default ManualConstruction;
