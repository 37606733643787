export const POST_DELETE_MANUAL_CONSTRUCTION_BEGIN = 'POST_DELETE_MANUAL_CONSTRUCTION_BEGIN';
export const POST_DELETE_MANUAL_CONSTRUCTION_SUCCESS = '';
export const POST_DELETE_MANUAL_CONSTRUCTION_FAILURE = 'POST_DELETE_MANUAL_CONSTRUCTION_FAILURE';
export const RESET_POST_DELETE_MANUAL_CONSTRUCTION_SUCCESSFUL = 'RESET_POST_DELETE_MANUAL_CONSTRUCTION_SUCCESSFUL';

export const DELETE_ELEMENTS_BY_OWNERID_BEGIN = 'DELETE_ELEMENTS_BY_OWNERID_BEGIN';
export const DELETE_ELEMENTS_BY_OWNERID_SUCCESS = 'DELETE_ELEMENTS_BY_OWNERID_SUCCESS';
export const DELETE_ELEMENTS_BY_OWNERID_FAILURE = 'DELETE_ELEMENTS_BY_OWNERID_FAILURE';

export const postDeleteManualConstructionBegin = () => ({
    type: POST_DELETE_MANUAL_CONSTRUCTION_BEGIN
})
export const postDeleteManualConstructionSuccess = () => ({
    type: POST_DELETE_MANUAL_CONSTRUCTION_SUCCESS
})
export const postDeleteManualConstructionFailure = (error: string, exception: Error) => ({
    type: POST_DELETE_MANUAL_CONSTRUCTION_FAILURE,
    payload: { message: error, detail: exception.message }
})
export const resetDeleteManualConstructionSuccessful = () => ({
    type: RESET_POST_DELETE_MANUAL_CONSTRUCTION_SUCCESSFUL
})

export const deleteElementsByOwnerIdBegin = () => ({
    type: DELETE_ELEMENTS_BY_OWNERID_BEGIN
})
export const deleteElementsByOwnerIdSuccess = (numberOfDeletedObjects: number, message: string, detail: string) => ({
    type: DELETE_ELEMENTS_BY_OWNERID_SUCCESS,
    payload: {numberOfDeletedObjects, message: message, detail: detail}
})
export const deleteElementsByOwnerIdFailure = (error: string, exception: Error) => ({
    type: DELETE_ELEMENTS_BY_OWNERID_FAILURE,
    payload: { message: error, detail: exception.message }
})