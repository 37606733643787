/*
* Replaces all occurances of 'ascii32' in a string with an actual space.
* Running the string value "Avsluttet,ascii32avsluttetascii32avascii32Saksbehandler" through ASCII32inString2Space returns "Avsluttet, avsluttet av Saksbehandler"
*/
export const ASCII32inString2Space = (stringWithascii32InsteadOfSpace: string): string => {
    return stringWithascii32InsteadOfSpace.toLowerCase().replace(/ascii32/g, ' ')
}

/**
 * Swaps order of coordinates from eiter long-lat to lat-long, or lat-long to long-lat, for given array / arrays
 * @param coordinates
 */
export function swapCoordinatesOrder(coordinates: any) {
    //if not point
    if (coordinates[0].length) {
        for (let i = 0; i < coordinates.length; i++) {
            // if polygon
            if (coordinates[i].length > 2) {
                for (let j = 0; j < coordinates[i].length; j++) {
                    const tmp = coordinates[i][j][0];
                    coordinates[i][j][0] = coordinates[i][j][1];
                    coordinates[i][j][1] = tmp;
                }
            }
            else if (coordinates[i].length > 1) {
                const tmp = coordinates[i][0];
                coordinates[i][0] = coordinates[i][1];
                coordinates[i][1] = tmp;
            }
        }
    } else {
        const tmp = coordinates[0];
        coordinates[0] = coordinates[1];
        coordinates[1] = tmp
    }
    return coordinates;
}