import { IOrganisation } from './IOrganisation';

export const FETCH_ORGANISATION_BY_ORGNR_BEGIN = 'FETCH_ORGANISATION_BY_ORGNR_BEGIN';
export const FETCH_ORGANISATION_BY_ORGNR_SUCCESS = 'FETCH_ORGANISATION_BY_ORGNR_SUCCESS';
export const FETCH_ORGANISATION_BY_ORGNR_FAILURE = 'FETCH_ORGANISATION_BY_ORGNR_FAILURE';
export const FETCH_ORGANISATION_BY_ORGNR_SUCCESS_WITH_WARNING = 'FETCH_ORGANISATION_BY_ORGNR_SUCCESS_WITH_WARNING';

export const UPDATE_ORGANISATION_BEGIN = 'UPDATE_ORGANISATION_BEGIN';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAILURE = 'UPDATE_ORGANISATION_FAILURE';

export const FETCH_ORGANISATION_API_KEY_BEGIN = 'FETCH_ORGANISATION_API_KEY_BEGIN';
export const FETCH_ORGANISATION_API_KEY_SUCCESS = 'FETCH_ORGANISATION_API_KEY_SUCCESS';
export const FETCH_ORGANISATION_API_KEY_FAILURE = 'FETCH_ORGANISATION_API_KEY_FAILURE';

export const GENERATE_ORGANISATION_API_KEY_BEGIN = 'GENERATE_ORGANISATION_API_KEY_BEGIN';
export const GENERATE_ORGANISATION_API_KEY_SUCCESS = 'GENERATE_ORGANISATION_API_KEY_SUCCESS';
export const GENERATE_ORGANISATION_API_KEY_FAILURE = 'GENERATE_ORGANISATION_API_KEY_FAILURE';

export const fetchOrganisationByOrgNrBegin = () => ({
  type: FETCH_ORGANISATION_BY_ORGNR_BEGIN
});
export const fetchOrganisationByOrgNrSuccess = (organisation: IOrganisation) => ({
  type: FETCH_ORGANISATION_BY_ORGNR_SUCCESS,
  payload: organisation
});
export const fetchOrganisationByOrgNrFailure = (error: string, exception: Error) => ({
  type: FETCH_ORGANISATION_BY_ORGNR_FAILURE,
  payload: { message: error, detail: exception.message }
});
export const fetchOrganisationByOrgNrSuccessWithWarning = (organisation: IOrganisation) => ({
  type: FETCH_ORGANISATION_BY_ORGNR_SUCCESS_WITH_WARNING,
  payload: { organisation, message: "Du må legge inn profildata for å importere data.", detail: "Det kan du gjøre ", link: { url: "profile", title: "her." } }
});

export const updateOrganisationBegin = () => ({
  type: UPDATE_ORGANISATION_BEGIN
});
export const updateOrganisationSuccess = (message: string) => ({
  type: UPDATE_ORGANISATION_SUCCESS,
  payload: { message: message }
});
export const updateOrganisationFailure = (error: string, exception: Error) => ({
  type: UPDATE_ORGANISATION_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const fetchOrganisationAPIKeyBegin = () => ({
  type: FETCH_ORGANISATION_API_KEY_BEGIN
});
export const fetchOrganisationAPIKeySuccess = (APIKey: string) => ({
  type: FETCH_ORGANISATION_API_KEY_SUCCESS,
  payload: APIKey
});
export const fetchOrganisationAPIKeyFailure = (error: string, exception: Error) => ({
  type: FETCH_ORGANISATION_API_KEY_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const generateOrganisationAPIKeyBegin = () => ({
  type: GENERATE_ORGANISATION_API_KEY_BEGIN
});
export const generateOrganisationAPIKeySuccess = (APIKey: string, message: string) => ({
  type: GENERATE_ORGANISATION_API_KEY_SUCCESS,
  payload: {message: message, APIKey}
});
export const generateOrganisationAPIKeyFailure = (error: string, exception: Error) => ({
  type: GENERATE_ORGANISATION_API_KEY_FAILURE,
  payload: { message: error, detail: exception.message }
});