export const POST_FILE_BEGIN = "POST_FILE_BEGIN";
export const POST_FILE_SUCCESSFULL = "POST_FILE_SUCCESSFULL";
export const POST_FILE_FAILURE = "POST_FILE_FAILURE";

export const POST_PRODUCT_BEGIN = "POST_PRODUCT_BEGIN";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";
export const POST_PRODUCT_FAILURE = "POST_PRODUCT_FAILURE";

export const RESET_POST_PRODUCT_SUCCESSFULL = "RESET_POST_PRODUCT_SUCCESSFULL";

export const postFileBegin = () => ({
  type: POST_FILE_BEGIN,
});
export const postFileSuccess = (message: string, detail: string) => ({
  type: POST_FILE_SUCCESSFULL,
  payload: { message: message, detail: detail },
});
export const postFileFailure = (error: string, exception: Error) => ({
  type: POST_FILE_FAILURE,
  payload: {
    message: error,
    detail: exception.message.replaceAll("\\n", "<br />"),
  },
});

export const postProductBegin = () => ({
  type: POST_PRODUCT_BEGIN,
});
export const postProductSucess = (message: string) => ({
  type: POST_PRODUCT_SUCCESS,
  payload: { message: message },
});
export const postProductFailure = (error: string, exception: Error) => ({
  type: POST_PRODUCT_FAILURE,
  payload: { message: error, detail: exception.message },
});

export const resetPostProductSuccessfull = () => ({
  type: RESET_POST_PRODUCT_SUCCESSFULL,
});
