import React from "react";
import { IProduct, InfrastructureStatus, getEnumKeyByValue } from "./IProduct";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { swapCoordinatesOrder } from "./../common/utilities/parser";

type ProductEditRowViewProps = {
  product?: IProduct;
  productBeingEdited?: IProduct;
  handleInputChange: Function;
  handleSave: Function;
  handleAbortEdit: Function;
  showHeaders?: boolean;
};

export function ProductEditRowView(props: ProductEditRowViewProps) {
  const coordinatesInLatLong =
    props.productBeingEdited?.geometry?.coordinates &&
    swapCoordinatesOrder(
      JSON.parse(JSON.stringify(props.productBeingEdited.geometry.coordinates))
    );

  return (
    <div>
      {props.showHeaders && (
        <UIKit.Grid
          options="margin:uk-margin-small;"
          match={true}
          className="uk-child-width-1-4@m uk-flex uk-flex-middle"
        >
          <div className="uk-text-bold uk-text-medium">Lokasjon</div>
          <div className="uk-text-bold uk-text-medium">Navn (frivillig)</div>
          <div className="uk-text-bold uk-text-medium">Status</div>
          <div></div>
        </UIKit.Grid>
      )}
      <UIKit.Grid
        options="margin:uk-margin-small;"
        match={true}
        className="uk-margin-remove-top uk-child-width-1-4@m uk-flex uk-flex-middle"
      >
        <div className="uk-overflow-hidden uk-text-truncate">
          {coordinatesInLatLong.map((coordinate: any, index: number) => {
            if (!coordinate.length)
              return (
                coordinate + (index + 1 !== coordinatesInLatLong.length && ",")
              );
            return coordinate + " ";
          }) || ""}
        </div>
        <div>
          <input
            name="name"
            type="text"
            placeholder="navn"
            className="uk-display-block uk-input"
            defaultValue={props.productBeingEdited?.name}
            onChange={(e) => props.handleInputChange(e)}
          />
        </div>
        <div>
          <select
            name="status"
            className="uk-select"
            defaultValue={props.productBeingEdited?.status}
            onChange={(e) => props.handleInputChange(e)}
          >
            {Object.values(InfrastructureStatus).map(
              (status: string, index: number) => (
                <option
                  key={index}
                  value={getEnumKeyByValue(InfrastructureStatus, status)}
                >
                  {status}
                </option>
              )
            )}
          </select>
        </div>
        <div>
          <div>
            <button
              className="uk-margin-left uk-button uk-button-primary"
              onClick={() => props.handleSave()}
            >
              Lagre
            </button>
            <button
              className="uk-button-link uk-button uk-margin-left"
              onClick={() => props.handleAbortEdit()}
            >
              Avbryt
            </button>
          </div>
        </div>
      </UIKit.Grid>
    </div>
  );
}
