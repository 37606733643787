import{
    POST_DELETE_MANUAL_CONSTRUCTION_BEGIN,
    POST_DELETE_MANUAL_CONSTRUCTION_FAILURE,
    POST_DELETE_MANUAL_CONSTRUCTION_SUCCESS,
    RESET_POST_DELETE_MANUAL_CONSTRUCTION_SUCCESSFUL,
    // DELETE_CONSTRUCTION_BY_OWNERID_BEGIN,
    // DELETE_CONSTRUCTION_BY_OWNERID_FAILURE,
    // DELETE_CONSTRUCTION_BY_OWNERID_SUCCESS,
    // DELETE_PRODUCTS_BY_OWNERID_BEGIN,
    // DELETE_PRODUCTS_BY_OWNERID_FAILURE,
    // DELETE_PRODUCTS_BY_OWNERID_SUCCESS,
    DELETE_ELEMENTS_BY_OWNERID_BEGIN,
    DELETE_ELEMENTS_BY_OWNERID_SUCCESS,
    DELETE_ELEMENTS_BY_OWNERID_FAILURE
} from './delete.actions';

export type DeleteState = {
    postDeleteManualConstructionLoading: boolean;
    postDeleteManualConstructionError?: string;
    postDeleteManualConstructionSuccessful: boolean;
    deleteConstructionByOwnerIdLoading: boolean;
    deleteConstructionByOwnerIdError?: string;
    deleteProductsByOwnerIdLoading: boolean;
    deleteProductsByOwnerIdError?: string;
}

export default function deleteReducer(
    state: DeleteState = {
        postDeleteManualConstructionLoading: false,
        postDeleteManualConstructionSuccessful: false,
        deleteConstructionByOwnerIdLoading: false,
        deleteProductsByOwnerIdLoading: false
    },
    action: any
) {
    switch (action.type) {
        case POST_DELETE_MANUAL_CONSTRUCTION_BEGIN:
            return {
                ...state,
                postDeleteManualConstructionLoading: true,
                postDeleteManualConstructionError: undefined
            };
        case POST_DELETE_MANUAL_CONSTRUCTION_SUCCESS:
            return {
                ...state,
                postDeleteManualConstructionLoading: false,
                postDeleteManualConstructionSuccessful: true,
                postDeleteManualConstructionError: undefined
            };
        case POST_DELETE_MANUAL_CONSTRUCTION_FAILURE:
            return {
                ...state,
                postDeleteManualConstructionLoading: false,
                postDeleteManualConstructionError: action.payload.message
            };
        case RESET_POST_DELETE_MANUAL_CONSTRUCTION_SUCCESSFUL:
            return {
                ...state,
                postDeleteManualConstructionSuccessful: false,
            };
        case DELETE_ELEMENTS_BY_OWNERID_BEGIN:
            return {
                ...state,
                deleteProductsByOwnerIdLoading: true
            };
        case DELETE_ELEMENTS_BY_OWNERID_SUCCESS:
            return {
                ...state,
                deleteProductsByOwnerIdLoading: false
            };
        case DELETE_ELEMENTS_BY_OWNERID_FAILURE:
            return {
                ...state,
                deleteProductsByOwnerIdLoading: false,
                deleteProductsByOwnerIdError: action.payload.message
            };
        default:
            return state;
    }
}