import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_FAILED,
    LOGOUT_SUCCESS,
} from './auth.actions';
import { User } from 'oidc-client';

export type AuthState = {
    user?: User;
    error?: Error;
    awaitingLogout: boolean;
};

let reducer = (state: AuthState = { awaitingLogout: true }, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: undefined
            };
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                error: undefined,
                user: undefined,
                awaitingLogout: false
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}
export default reducer;